import React from 'react'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

function ProductImgs(props) {
  return (
    <div className="lg:mx-auto lg:container flex justify-center">
      <div className="lg:px-2 px-4 py-4 w-full">
        <CarouselProvider
          className="relative"
          naturalSlideWidth={100}
          isIntrinsicHeight={true}
          totalSlides={props.image.length}
          visibleSlides={1}
          step={1}
          infinite={true}
        >
          <div className="js-flickity flex justify-center items-center">
            <ButtonBack
              role="button"
              aria-label="slide backward"
              className="w-12 h-12 md:w-14 md:h-14 sm:w-10 sm:h-10 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-gray-200 absolute z-20 left-0 lg:ml-8 md:ml-4 sm:-ml-4 -ml-4 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
              id="prev"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="black"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonBack>
            <Slider>
              {props.image.map((image ,i) => (
                <Slide key={i} index={i}>
                  <div className="lg:px-32 md:px-24 sm:px-2 px-2 w-full">
                    <div className="relative w-full h-full">
                      <img
                      key={i}
                        src={image}
                        alt={i}
                        className="object-center object-cover h-full"
                      />
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
            <ButtonNext
              role="button"
              aria-label="slide forward"
              className="w-12 h-12 md:w-14 md:h-14 sm:w-10 sm:h-10 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-gray-200 absolute z-20 right-0 lg:mr-8 md:mr-4 sm:-mr-4 -mr-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              id="next"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L1 13"
                  stroke="black"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonNext>
          </div>
        </CarouselProvider>
      </div>
    </div>
  )
}

export default ProductImgs
