import React, { useState, useEffect } from "react";
import Dashboard from "../../components/Admin/Dashboard/Dashboard";
import { useUsersContext } from "../../context/UserContext";
import SlideOvers from "../../components/Admin/Content/SlideOvers";
import ViewCreateUser from "./SlideOvers/Users/ViewCreateUser";

function ViewAdminUsers() {
  const [modalOpen, setModalOpen] = useState(false);
  const [tableData,setTableData]= useState([])

  const viewSlide = () => {
    setModalOpen(true);
  };
  const { users, getAllUsers } = useUsersContext();

  useEffect(() => {
    getAllUsers();
    setTableData(users)
  }, []);

  useEffect(() => {
    setTableData(users)
  }, [users]);

  function handleSearch(e) {
    if (e.target.value === "") {
      getAllUsers();
    }

    const filteredData = users.filter((row) => {
      return row["firstName"]
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setTableData(filteredData)
  }

  const columns = [
    { name: "FIRSTNAME", selector: "firstName" },
    { name: "LASTNAME", selector: "lastName" },
    { name: "NIC", selector: "NIC" },
    { name: "DATE OF BIRTH", selector: "DOB" },
    { name: "CONTACT NUMBERS", selector: "contactNumber1" },
    { name: "EMAIL ADDRESS", selector: "email" },
    { name: "USER ROLE", selector: "userRole" },
    { name: "ACTIONS", selector: "actions" },
  ];

  return (
    <div>
      <Dashboard
        tab={1}
        title="Users"
        search="Search users..."
        button="Create New User"
        viewSlide={viewSlide}
        columns={columns}
        data={tableData}
        handleSearch={handleSearch}
      />
      <div>
        {modalOpen && (
          <SlideOvers
            content={<ViewCreateUser setOpenModal={setModalOpen} />}
          />
        )}
      </div>
    </div>
  );
}

export default ViewAdminUsers;
