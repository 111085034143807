import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUsersContext } from '../../../../context/UserContext';
import Loading from '../../Auth/Loading';

function ViewEditUser({ userId, onClose, onUpdate }) {
  const { getUserByID, updateUser } = useUsersContext();
  const [userData, setUserData] = useState(null);

  const onSubmit = async (data) => {
    try {
      await updateUser(userId, data);
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {}
  };
  const userDetailsSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .min(4, 'Atleast 3 characters required')
      .max(500, "Can't exceed 500 characters"),
    lastName: Yup.string()
      .required('Last Name is required')
      .min(4, 'Atleast 3 characters required')
      .max(500, "Can't exceed 500 characters"),
    DOB: Yup.string().required('DOB is required'),
    contactNumber1: Yup.string()
      .required('Contact Number 1 is required')
      .matches(
        '^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$',
        'Please enter a valid telephone number'
      ),
    email: Yup.string()
      .required('Email is required')
      .email('Valid email address is required')
      .min(4, 'Atleast 4 characters required')
      .max(200, "Can't exceed 200 characters"),
    address: Yup.string().required('Address is required'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm({
    resolver: yupResolver(userDetailsSchema),
    defaultValues: async () => {
      const users = await getUserByID(userId);
      return {
        authKey: users[0].authKey,
        userRole: users[0].userRole,
        userName: users[0].userName,
        SK: users[0].SK,
        firstName: users[0].firstName,
        lastName: users[0].lastName,
        NIC: users[0].NIC,
        DOB: users[0].DOB,
        email: users[0].email,
        address: users[0].address,
        contactNumber1: users[0].contactNumber1,
        contactNumber2: users[0].contactNumber2,
        password: users[0].password,
      };
    },
    mode: 'all',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const users = await getUserByID(userId);
        setUserData(users[0]);
      } catch (error) {}
    };
    fetchUserData();
  }, [userId, getUserByID]);

  if (!userData) {
    return <Loading />;
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-4xl overflow-y-auto'
    >
      <div className='h-0 flex-1'>
        <div className='flex flex-1 flex-col justify-between'>
          <div className='divide-y divide-gray-200 px-4 sm:px-6'>
            <div className='space-y-6 pb-5'>
              <div className='flex justify-between border-b text-gray-900 text-3xl'>
                <b>Edit User Details</b>
                <XMarkIcon
                  onClick={() => onClose()}
                  className='h-10 w-10 text-gray-500 cursor-pointer'
                />
              </div>

              <div className='overflow-y-auto'>
                <label className='block text-2xl font-xl leading-6 text-black border-b border-gray-900/10'>
                  Basic Details
                </label>
                <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-2 '>
                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='firstName'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      First Name
                    </label>
                    <div className='mt-2'>
                      <input
                        type='text'
                        id='firstName'
                        {...register('firstName')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.firstName?.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='lastName'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Last Name
                    </label>
                    <div className='mt-2'>
                      <input
                        type='text'
                        id='lastName'
                        {...register('lastName')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.lastName?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='NIC'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      NIC
                    </label>
                    <div className='mt-2'>
                      <input
                        type='text'
                        id='NIC'
                        {...register('NIC')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='DOB'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      DOB
                    </label>
                    <div className='mt-2'>
                      <input
                        type='text'
                        id='DOB'
                        disabled
                        {...register('DOB')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.DOB?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='contactNumber1'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Contact Number #1
                    </label>
                    <div className='mt-2'>
                      <input
                        type='text'
                        id='contactNumber1'
                        {...register('contactNumber1')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.contactNumber1?.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='contactNumber2'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Contact Number #2
                    </label>
                    <div className='mt-2'>
                      <input
                        type='text'
                        id='contactNumber2'
                        {...register('contactNumber2')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </div>
                  <div className='col-span-full'>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Email Address
                    </label>
                    <div className='mt-2'>
                      <input
                        type='text'
                        id='email'
                        {...register('email')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.email?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-full'>
                    <label
                      htmlFor='address'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Address
                    </label>
                    <div className='mt-2'>
                      <textarea
                        type='text'
                        cols={25}
                        rows={5}
                        id='address'
                        {...register('address')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.address?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className='block text-2xl font-xl leading-6 text-black border-b border-gray-900/10 mb-2'>
                  Credentials
                </label>
                <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-2 '>
                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='userName'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Username
                    </label>
                    <div className='mt-2'>
                      <input
                        disabled
                        type='text'
                        id='userName'
                        {...register('userName')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.userName?.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='password'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Password
                    </label>
                    <div className='mt-2'>
                      <input
                        type='password'
                        id='password'
                        {...register('password')}
                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.password?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-shrink-0 justify-end mt-10 border-t border-gray-900/10  px-4 py-4'>
                <button
                  type='button'
                  className='ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-gray-800 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button
                  disabled={!isDirty || !isValid || isSubmitting}
                  type='submit'
                  className={`ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-blue-800 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ${
                    !isDirty || !isValid || isSubmitting
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ViewEditUser;
