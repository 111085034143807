import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';
import QuotationDetails from '../../../../components/Admin/Content/QuotationDetails';

import { useQuotationContext } from '../../../../context/QuotationContex';
import Loading from '../../Auth/Loading';
import { SpinnerCircular } from 'spinners-react';

function ViewQuatationDetails({ onClose, qutationId, name, status }) {
  const [quotation, setQuotation] = useState(null);
  const [total, setTotal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('wholesale');
  const {
    getQuotationByID,
    approveQuotation,
    getProductDetails,
    updateQuotation,
  } = useQuotationContext();

  useEffect(() => {
    let total = 0.0;
    const fetchQuotation = async () => {
      const QuotationData = await getQuotationByID(qutationId, name);
      setQuotation(QuotationData);
      setSelectedOption(QuotationData.products[0].priceType);
      for (let i = 0; i < QuotationData.products.length; i++) {
        total += QuotationData.products[i].totalValue;
      }
      setTotal(total);
    };
    fetchQuotation();
  }, []);

  if (!quotation) {
    return <Loading />;
  }

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    const selected = event.target.value;
    priceUpdate(selected);
  };

  const priceUpdate = async (type) => {
    setIsLoading(true);
    let total = 0.0;
    for (let i = 0; i < quotation.products.length; i++) {
      const detail = await getProductDetails(quotation.products[i].itemNo);
      quotation.products[i].priceType = type;
      quotation.products[i].unitValue = detail.price[type]
        ? detail.price[type]
        : 0;
      quotation.products[i].totalValue =
        quotation.products[i].qty * quotation.products[i].unitValue;
      total += quotation.products[i].totalValue;
    }
    setTotal(total);
    setIsLoading(false);
  };

  const onSubmit = async () => {
    const data = {
      PK: qutationId,
      SK: name,
    };

    try {
      await updateQuotation(quotation);
      await approveQuotation(data);
      onClose();
    } catch (error) {}
  };

  const getDate = (dateStr) => {
    return dateStr.split('T')[0];
  };

  const columns = [
    { name: 'ITEM NO', selector: 'itemNo' },
    { name: 'MC', selector: 'mc' },
    { name: 'PICTURE', selector: 'imageUrl' },
    { name: 'PACKING', selector: 'packing' },
    { name: 'DESCRIPTION', selector: 'description' },
    { name: 'COLOR REFERENCES', selector: 'colorRef' },
    { name: 'QUANTITY', selector: 'qty' },
    { name: 'UNIT VALUE', selector: 'unitValue' },
    { name: 'TOTAL VALUE', selector: 'totalValue' },
    { name: 'CBM', selector: 'cbm' },
    { name: 'REMARKS', selector: 'remarks' },
  ];
  const table_one_columns = [
    { name: 'ITEM NO', selector: 'itemNo' },
    { name: 'PICTURE', selector: 'imageUrl' },
    { name: 'LENGTH', selector: 'length' },
    { name: 'WIDTH', selector: 'width' },
    { name: 'HEIGHT', selector: 'height' },
    { name: 'DESCRIPTION', selector: 'description' },
    { name: 'QUANTITY', selector: 'qty' },
    { name: 'UNIT VALUE', selector: 'unitValue' },
    { name: 'TOTAL VALUE', selector: 'totalValue' },
  ];
  const table_two_columns = [
    { name: 'ITEM NO', selector: 'itemNo' },
    { name: 'IC', selector: 'packing' },
    { name: 'MC', selector: 'mc' },
    { name: 'MC QTY', selector: 'mcQty' },
    { name: 'COLOR REFERENCES', selector: 'colorRef' },
    { name: 'COLOR CUSTOMIZATION', selector: 'customisation' },
    { name: 'PCS PER 1x20 FT', selector: 'pcsPer20Feet' },
    { name: 'REMARKS', selector: 'remarks' },
  ];
  const table_three_columns = [
    { name: 'ITEM NO', selector: 'itemNo' },
    { name: 'MASTER CBM', selector: 'masterCbm' },
    { name: 'ITEM CBM', selector: 'itemCbm' },
    { name: 'TOTAL CBM', selector: 'totalCbm' },
  ];

  var products = [];

  let output = {
    length: '',
    width: '',
    height: '',
  };
  quotation.products.forEach((item) => {
    output.length = item.dimensions.length;
    output.width = item.dimensions.width;
    output.height = item.dimensions.height;

    let newitem = {
      ...item,
      length: output.length,
      width: output.width,
      height: output.height,
    };

    products.push(newitem);
  });

  console.log(products);

  return (
    <form className='flex flex-col divide-y divide-gray-200 bg-white shadow-4xl'>
      <div className='h-0 flex-1'>
        <div className='flex flex-1 flex-col justify-between'>
          <div className='divide-y divide-gray-200 px-4 sm:px-6'>
            <div className='pb-5 pt-6'>
              <div className='flex justify-between border-b-4 text-gray-900 text-3xl'>
                <b>Edit Quotation Details</b>
                <XMarkIcon
                  onClick={() => onClose()}
                  className='h-10 w-10 text-gray-500 cursor-pointer'
                />
              </div>
              <div className='flex justify-center border-b-4 text-gray-900 text-3xl'>
                <b className='mb-5'> QUOTATION</b>
              </div>
              <div className='grid w-full lg:grid-cols-4 md:grid-cols-4  sm:grid-cols-6 md:col-span-2 text-gray-900 text-xl  border-b-4'>
                <div className='mx-2 lg:border-r-2 lg:border-gray-200 lg:border-solid md:border-r-2 md:border-gray-200 md:border-solid '>
                  <img
                    className='w-12'
                    src='https://adi-images.s3.us-east-2.amazonaws.com/images/logo.JPG'
                    alt='logo'
                  />
                  <span className='self-center text-xs font-base whitespace-nowrap text-blue-900'>
                    Art Decoration International
                    <br />
                    (Pvt) Ltd
                  </span>
                </div>
                <div className='mx-2 lg:border-r-2 lg:border-gray-200 lg:border-solid md:border-r-2 md:border-gray-200 md:border-solid '>
                  <span className='self-center text-xs font-base whitespace-nowrap text-blue-900'>
                    Yatiyana Road,
                    <br />
                    Galtude,
                    <br />
                    Bandaragama, <br />
                    Sri Lanka.
                  </span>
                </div>
                <div className='mx-2 lg:border-r-2 lg:border-gray-200 lg:border-solid md:border-r-2 md:border-gray-200 md:border-solid '>
                  <span className='self-center text-xs font-base whitespace-nowrap text-blue-900'>
                    Tel: 94 (0) 38 22 90093
                    <br />
                    94 (0) 38 56 78731
                    <br /> 94 (0) 38 56 78732 <br />
                    Fax: 94 (0) 38 22 90092
                  </span>
                </div>
                <div className='mx-2'>
                  <span className='self-center text-xs font-base whitespace-nowrap text-blue-900'>
                    Email: info@artdecor.com
                    <br />
                    Web: www.artdecorint.com
                  </span>
                </div>
              </div>
              <div className=' grid w-full lg:grid-cols-4 md:grid-cols-4  sm:grid-cols-6 md:col-span-2 text-gray-900 text-xl'>
                <div className='my-4 mx-2'>
                  <span className='self-center text-sm font-base whitespace-nowrap text-blue-900'>
                    <span className='text-lg font-semibold'>
                      {' '}
                      Customer Details
                    </span>
                    <br />
                    {quotation.customerName}
                  </span>
                </div>
                <div className='mx-2 lg:border-r-2 lg:border-gray-200 lg:border-solid md:border-r-2 md:border-gray-200 md:border-solid '>
                  <div className='mx-auto my-6'>
                    <select
                      value={selectedOption}
                      onChange={handleChange}
                      className='block p-2 text-sm text-blue-900 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300'
                    >
                      <option value='wholesale'>Wholesaler</option>
                      <option value='retail'>Retailer</option>
                    </select>
                  </div>
                </div>
                <div className='mx-2 my-4'>
                  <span className='flex justify-start text-md font-base whitespace-nowrap text-blue-900 '>
                    Quote ID: {quotation.quotationNumber}
                  </span>
                  <span className='flex justify-start text-md  font-base whitespace-nowrap text-blue-900'>
                    Date: {getDate(quotation.quotationReceivedDate)}
                  </span>
                </div>
                <div className='mx-2'></div>
              </div>
              {isLoading ? (
                <div
                  className='flex items-center justify-center'
                  style={{ margin: '20px' }}
                >
                  <SpinnerCircular color='#3F70FD' />
                </div>
              ) : (
                <div>
                  <QuotationDetails
                    columns={table_one_columns}
                    data={products}
                  />
                  <div className=' grid w-full lg:grid-cols-6 md:grid-cols-12  sm:grid-cols-6 md:col-span-2 text-gray-900 text-xl border-t-4 border-b-4'>
                    <div className='mx-2'>
                      <span className='self-center text-md font-base whitespace-nowrap text-blue-900'>
                        SubTotal
                      </span>
                    </div>
                    <div className='mx-2'></div>
                    <div className='mx-2'></div>
                    <div className='mx-2'></div>
                    <div className='mx-2 lg:border-r-2 lg:border-gray-200 lg:border-solid md:border-r-2 md:border-gray-200 md:border-solid '></div>
                    <div className='mx-2'>
                      <span className='flex justify-center my-2 text-xs font-base whitespace-nowrap text-blue-900'>
                        <b> {total.toFixed(2)}</b>
                      </span>
                    </div>
                  </div>
                  <QuotationDetails
                    columns={table_two_columns}
                    data={quotation.products}
                  />
                  <QuotationDetails
                    columns={table_three_columns}
                    data={quotation.products}
                  />
                </div>
              )}
              <div className=' grid w-full lg:grid-cols-4 md:grid-cols-6 my-2 sm:grid-cols-6 md:col-span-2 text-gray-900 text-xl border-t-4 border-b-4'>
                <div>
                  <span className='self-center text-xs font-base whitespace-nowrap text-blue-900'>
                    <i>*Terms & Conditions fully customized to your website.</i>
                  </span>
                </div>
              </div>
              <div className='flex flex-shrink-0 justify-end mt-10 border-t border-gray-900/10  px-4 py-4'>
                <button
                  type='button'
                  className='ml-4 py-2 lg:w-1/4 md:w-1/2 sm:w-full w-full bg-gray-400 hover:bg-gray-500 focus:ring-gray-600 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'
                  onClick={() => onClose()}
                >
                  Close
                </button>

                <button
                  type='button'
                  className={`ml-4 py-2 lg:w-1/4 md:w-1/3 sm:w-full w-full bg-green-400  focus:ring-green-700 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ${
                    status === 'sent' ? 'opacity-50' : 'hover:bg-green-500'
                  }`}
                  onClick={onSubmit}
                  disabled={status === 'sent' ? true : false}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ViewQuatationDetails;
