import React, { useState } from 'react'

function Filter(props) {
  const [show, setShow] = useState(false)
  const [filter, setFilter] = useState(props.name)

  return (
    <div className="relative">
      <div
        className="bg-white flex justify-between border rounded w-auto"
        onClick={() => setShow(!show)}
      >
        <div className="bg-white items-center flex ">
          <div className="cursor-pointer text-gray-600 ml-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="lg:w-6 md:w-6 sm:w-4 w-4 lg:h-6 md:h-6 sm:h-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
          </div>
        </div>
        <p className="px-2 py-2 text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs font-normal">
          {filter}
        </p>
      </div>
      {show && (
        <ul className="visible transition duration-300 opacity-100 bg-white shadow rounded mt-2 py-1 w-auto absolute cursor-pointer z-10">
          {props.options.map((option) => (
            <li
              className="cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center"
              onClick={() => {
                setFilter(option.label)
                setShow(false)
                props.handleFilter(option.value)
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Filter
