import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useAuth } from '../../../context'
import Alert from '../../../components/Admin/Content/Alert'
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import Header from '../../../components/Shared/Header'
import Footer from '../../../components/Shared/Footer'
import ButtonLoader from "../../../components/Shared/ButtonLoader";

function ViewAdminLogin() {
  const navigate = useNavigate()
  const { signIn } = useAuth()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSuccessMessage(null)
    setErrorMessage(null)
    setIsLoading(true)
    try {
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()

      // User does not need to reset their password, handle successful login
      await signIn(user)
      setIsLoading(false)
      setSuccessMessage('Logged in successfully')
      navigate('/admin/users')
    } catch (error) {
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }
  return (
    <div>
      {successMessage && (
        <Alert
          icon={CheckCircleIcon}
          bgColor="sm:fixed sm:top-28 sm:right-0 relative border-2 border-green-600 m-2 rounded-md bg-green-50 p-4"
          iconColor="h-7 w-7 text-green-400"
          text={successMessage}
          buttonColor="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
          icon2={XMarkIcon}
        />
      )}
      {errorMessage && (
        <Alert
          icon={XCircleIcon}
          bgColor="sm:fixed sm:top-28 sm:right-0 relative  border-2 border-red-600 m-2 rounded-md bg-red-50 p-4"
          iconColor="h-7 w-7 text-red-400"
          text={errorMessage}
          buttonColor="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
          icon2={XMarkIcon}
        />
      )}
      <Header page={5} />
      <div className="flex h-screen ">
        <div className="hidden sm:block w-1/3">
          <div className="m-20 py-24">
            <p className="font-bold text-gray-700 text-lg">
              Start your journey with us
            </p>
            <p className="py-1 text-md text-gray-400 md:text-sm">
              Art Decor makes Everything Better.
            </p>
          </div>
          <div className="m-20">
            <img
              src="https://i.ebayimg.com/images/g/pfMAAOSw1SJj6Kmx/s-l500.jpg"
              className="h-full md:h-80"
            />
          </div>
        </div>
        <div className="lg:w-2/3 sm:w-2/3 md:w-2/3 bg-gray-100 flex flex-col justify-center lg:px-10 px-5 ">
          <div className="bg-white w-full rounded-lg lg:px-60 md:px-20 py-10 px-5">
            <div class="block  font-semibold text-xl self-start text-gray-700">
              <h2 class="leading-relaxed ">Welcome Back</h2>
              <p class="text-sm text-gray-400">
                Sign in by entering the information below.
              </p>
            </div>
            <form
              onSubmit={handleSubmit}
              className="w-full bg-white max-w-[400px]  mx-auto p-8 px-8 rounded-lg sm:rounded-sm md:rounded-md  "
            >
              <div className="">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div className="flex flex-col">
                    <label className="leading-loose text-gray-500 text-sm">
                      Username
                    </label>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      required
                      value={username}
                      onChange={(event) => setUsername(event.target.value)}
                      className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="leading-loose text-gray-500 text-sm">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      required
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                    />
                  </div>
                  <div class="mb-2 py-2">
                    <div class="lg:flex lg:justify-between md:justify-between md:flex  sm:grid sm:grid-cols-1 ">
                      <div>
                        <label
                          class="block text-gray-500 font-bold mr-2"
                          for="remember"
                        >
                          <input
                            class="ml-2 leading-tight"
                            type="checkbox"
                            id="remember"
                            name="remember"
                          />
                          <span class="text-sm  px-2">Remember Me</span>
                        </label>
                      </div>
                      <div>
                        <a
                          class="font-bold text-sm  text-blue-500 hover:text-orange-800 "
                          href="/forgot-password"
                        >
                          Forgot Password
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pt-4 flex items-center space-x-4">
                  <button
                    type="submit"
                    className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none"
                  >
                    <ButtonLoader isLoading={isLoading}></ButtonLoader>
                    Sign In
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ViewAdminLogin
