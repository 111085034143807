import React from 'react'
import { Link } from 'react-router-dom'

const tabs = [
  {
    id: 1,
    name: 'Home',
    link: '/',
  },
  {
    id: 2,
    name: 'Products',
    link: '/products',
  },
  {
    id: 3,
    name: 'New Arrivals',
    link: '/arrivals',
  },
  {
    id: 4,
    name: 'Contact Us',
    link: '/contactus',
  },
  {
    id: 5,
    name: 'Login',
    link: '/login',
  },
]

function Footer() {
  return (
    <div>
      <footer className="px-4 shadow md:px-6 md:py-6 sm:py-4 py-4 bg-black">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center mb-4 sm:mb-0">
            <img
              src="https://adi-images.s3.us-east-2.amazonaws.com/images/logoWhite.jpg"
              className="mr-3 lg:h-16 md:h-16 sm:h-12 h-12"
              alt="Artdecoint Logo"
            />
            <span className="self-center mt-2 lg:text-xl md:text-md sm:text-sm text-xs font-semibold whitespace-nowrap text-white">
              Art Decoration International (Pvt) Ltd
            </span>
          </div>
          <ul className="flex flex-wrap items-center mb-4 text-sm text-gray-300 sm:mb-0">
            {tabs.map((t) => (
              <li key={t.id}>
                <Link to={t.link}>
                  <p className="mr-4 hover:font-semibold md:mr-6 ">{t.name}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <hr className="my-4 sm:mx-auto border-gray-700 lg:my-4" />
        <span className="block text-sm sm:text-center text-gray-400">
          © 2022 Art Decoration International (Pvt) Ltd. All Rights Reserved.
        </span>
      </footer>
    </div>
  )
}

export default Footer
