import React from 'react'
import Filters from '../components/ProductCatalogue/Filters'
import Footer from '../components/Shared/Footer'
import Header from '../components/Shared/Header'
import Heading from '../components/Shared/Heading'
import InvitationCode from '../components/Shared/InvitationCode'

function ViewNewArrivals() {
  return (
    <div>
      {localStorage.getItem('code') ? (
        <>
          <Header page={3} />
          <Heading
            heading="New Arrivals"
            breadcrumb={[{ name: 'New Arrivals', url: '/arrivals' }]}
          />
          <Filters productType="new" />
          <Footer />
        </>
      ) : (
        <InvitationCode />
      )}
    </div>
  )
}

export default ViewNewArrivals
