import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useUsersContext } from '../../../../context/UserContext'

function ViewDeleteUser({ userId, onClose }) {
  const { deleteUsers } = useUsersContext()

  const handleDelete = (event) => {
    event.preventDefault()

    deleteUsers(userId)
    setTimeout(() => {
      onClose()
    }, 3000)
  }
  return (
    <div>
      <form onSubmit={handleDelete}>
        <div className="space-y-4">
          <div className="flex justify-between text-gray-900 text-3xl">
            <b>Delete User</b>
            <XMarkIcon
              onClick={() => onClose()}
              className="h-10 w-10 text-gray-500 cursor-pointer"
            />
          </div>
          <hr />
        </div>

        <div className="mt-48 border-blue-500 border-solid border-2 px-2 py-2 rounded-lg">
          <p className="text-gray-900 text-xl text-center">
            Are you sure that you want to delete this user? <br /> if so click
            on confirm to continue
          </p>
          <div className="flex justify-center space-x-4 mt-6">
            <button
              type="submit"
              className="lg:1/6 md:w-1/4 sm:w-1/2 w-1/2 px-4 lg:py-3 md:py-3 sm:py-2 py-2 leading-5  border border-blue-800 text-blue-800 hover:text-white transition-colors duration-200 transform bg-white hover:bg-blue-800 rounded-lg focus:outline-none "
              onClick={() => onClose()}
            >
              <div className="flex justify-center space-x-2">
                <span>Cancel</span>
              </div>
            </button>
            <button
              type="submit"
              className="lg:1/6 md:w-1/4 sm:w-1/2 w-1/2 px-4 lg:py-3 md:py-3 sm:py-2 py-2 leading-5  border border-blue-800 text-blue-800 hover:text-white transition-colors duration-200 transform bg-white hover:bg-blue-800 rounded-lg focus:outline-none "
            >
              <div className="flex justify-center space-x-2">
                <span>Confirm</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ViewDeleteUser
