import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';
import { useFeedbacksContext } from '../../../../context/FeedbackContext';
import Loading from '../../Auth/Loading';

function ViewFeedback({ onClose, feedbackId }) {
  const [feedback, setFeedback] = useState(null);
  const { getFeedbackByID } = useFeedbacksContext();

  useEffect(() => {
    const fetchFeedback = async () => {
      const feedbackData = await getFeedbackByID(feedbackId);
      setFeedback(feedbackData);
    };

    fetchFeedback();
  }, []);
  if (!feedback) {
    return <Loading />;
  }

  return (
    <form className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-4xl'>
      <div className='flex justify-between text-gray-900 text-3xl px-4 sm:px-6 pb-5 pt-6'>
        <b>Feedback details</b>
        <XMarkIcon
          onClick={() => onClose()}
          className='h-10 w-10 text-gray-500 cursor-pointer'
        />
      </div>
      <div className='flex-1 h-screen overflow-y-auto overflow-x-hidden'>
        <div className='flex flex-1 flex-col justify-between'>
          <div className='divide-y divide-gray-200 px-4 sm:px-6'>
            <div className='space-y-6 pb-5 pt-6'>
              {feedback.map((f) => (
                <div>
                  <div class='container mx-auto grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2  grid-cols-2 pt-1 gap-2'>
                    <div class='h-12 break-words'>
                      <label className='self-center text-lg font-base whitespace-nowrap text-gray-500'>
                        Visitor Name
                      </label>
                    </div>
                    <div className='h-12 break-words'>
                      <label>{f.name}</label>
                    </div>
                    <div className='h-12 break-words'>
                      <label className='self-center text-lg font-base whitespace-nowrap text-gray-500'>
                        Phone Number
                      </label>
                    </div>
                    <div className='h-12 break-words'>
                      <label>{f.phone}</label>
                    </div>
                    <div className='h-12 break-words'>
                      <label className='self-center text-lg font-base whitespace-nowrap text-gray-500'>
                        Email
                      </label>
                    </div>
                    <div className='h-12 break-words'>
                      <label>{f.email}</label>
                    </div>
                    <div className='h-12 break-words'>
                      <label className='self-center text-lg font-base whitespace-nowrap text-gray-500'>
                        Subject
                      </label>
                    </div>
                    <div className='h-12 break-words'>
                      <label>{f.subject}</label>
                    </div>

                    <div className='h-12 break-words'>
                      <label className='self-center text-lg font-base whitespace-nowrap text-gray-500'>
                        Message
                      </label>
                    </div>
                  </div>

                  <div className='h-12 col-span-full'>
                    <label>{f.message}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-shrink-0 justify-end px-4 py-4'>
        <button
          type='button'
          className='ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-gray-800 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default ViewFeedback;
