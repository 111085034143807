import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
import Color from '../components/Admin/Content/Color';
import Actions from '../components/Admin/Content/Actions';
import { AuthContext } from './AuthProvider';
import Alert from '../components/Admin/Content/Alert';
import {
  XMarkIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const { token } = useContext(AuthContext); // Accessing the token from the AuthContext

  const createProduct = async (newProduct) => {
    try {
      newProduct.color = {
        item_color: [newProduct.color.item_color],
        color_finish: [newProduct.color.color_finish],
      };
      newProduct.dimensions = {
        length: newProduct.dimensions.length.toString(),
        width: newProduct.dimensions.width.toString(),
        height: newProduct.dimensions.height.toString(),
      };
      newProduct.quantity = newProduct.quantity.toString();
      newProduct.price = {
        retail: newProduct.price.retail.toString(),
        wholesale: newProduct.price.wholesale.toString(),
      };

      await axios.post(`${process.env.REACT_APP_API_URL}/add-new`, newProduct, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuccessMsg(`New Product Created Successfully`);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSuccessMsg(null);
      }, 3000);
      getAllProducts();
    } catch (error) {
      setErrorMsg(`Failed to Create the New Product`);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg(null);
      }, 3000);
    }
  };
  const deleteProducts = async (productId, name) => {
    try {
      const product = await getProductByID(productId);
      const { imageUrls, qrCodeImageUrl } = product[0];

      const body = {
        imageUrls: imageUrls,
        qrCodeImageUrl: qrCodeImageUrl,
      };

      await axios.delete(
        `${
          process.env.REACT_APP_API_URL
        }/delete-product-by-id/${productId}/${encodeURIComponent(name)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: JSON.stringify(body),
        }
      );
      setSuccessMsg(`Product ${name} Deleted Successfully`);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSuccessMsg(null);
      }, 3000);
      getAllProducts();
    } catch (error) {
      setErrorMsg(`Failed to Delete Product ${name}`);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg(null);
      }, 3000);
      console.error(error);
    }
  };

  const getAllProducts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/all-products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        const prod = [];
        for (let i = 0; i < data.length; i++) {
          const temp_Prod = {
            PK: data[i].PK,
            SK: data[i].SK,
            qrCodeImageUrl: data[i].qrCodeImageUrl,
            dimensions:
              data[i].dimensions.length +
              'CM X ' +
              data[i].dimensions.width +
              'CM X ' +
              data[i].dimensions.height +
              'CM',
            colors: data[i].color ? <Color color={data[i].color} /> : null,
            actions: (
              <Actions
                selectedTab={4}
                productId={data[i].PK}
                name={data[i].SK}
              />
            ),
          };
          prod.push(temp_Prod);
        }
        setProducts(prod);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getProductByID = async (productId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${productId}`,
        {
          headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY },
        }
      );
      return response.data;
    } catch (error) {}
  };

  const getProductsByType = (productType) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products-by-type/${productType}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_X_API_KEY,
        },
      })
      .then((response) => {
        const data = response.data;
        const prod = [];
        for (let i = 0; i < data.length; i++) {
          const temp_Prod = {
            PK: data[i].PK,
            SK: data[i].SK,
            qrCodeImageUrl: data[i].qrCodeImageUrl,
            dimensions:
              data[i].dimensions.length +
              'CM X ' +
              data[i].dimensions.width +
              'CM X ' +
              data[i].dimensions.height +
              'CM',
            colors: data[i].color ? <Color color={data[i].color} /> : null,
            actions: (
              <Actions
                selectedTab={4}
                productId={data[i].PK}
                name={data[i].SK}
              />
            ),
          };
          prod.push(temp_Prod);
        }
        setProducts(prod);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateProduct = async (productId, updatedData) => {
    try {
      const itemColor = [];
      const colorFinish = [];

      for (const colorItem of updatedData.color) {
        itemColor.push(colorItem.item_color);
        colorFinish.push(colorItem.color_finish);
      }
      updatedData.color = {
        item_color: itemColor,
        color_finish: colorFinish,
      };
      updatedData.dimensions = {
        length: updatedData.dimensions.length.toString(),
        width: updatedData.dimensions.width.toString(),
        height: updatedData.dimensions.height.toString(),
      };
      updatedData.quantity = updatedData.quantity.toString();
      updatedData.price = {
        retail: updatedData.price.retail.toString(),
        wholesale: updatedData.price.wholesale.toString(),
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/update-product-details/${productId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMsg('Product Details Updated Successfully');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSuccessMsg(null);
      }, 3000);
      getAllProducts();
    } catch (error) {
      setErrorMsg('Failed to Update Product Details');
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg(null);
      }, 3000);
    }
  };

  const addNewColor = async (productId, updatedData) => {
    try {
      const product = await getProductByID(productId);

      const output = {
        color: {
          item_color: [],
          color_finish: [],
        },
      };
      product[0].color.forEach((item) => {
        output.color.item_color.push(item.item_color);
        output.color.color_finish.push(item.color_finish);
      });

      output.color.item_color.push(updatedData.item_color);
      output.color.color_finish.push(updatedData.color_finish);

      const updatedProd = {
        SK: product[0].SK,
        newSK: product[0].SK,
        newPK: product[0].PK,
        CBM: product[0].CBM.toString(),
        masterCbm: product[0].masterCbm.toString(),
        cbmValue: product[0].cbmValue.toString(),
        pcsPer20Feet: product[0].pcsPer20Feet.toString(),
        color: {
          item_color: output.color.item_color,
          color_finish: output.color.color_finish,
        },
        description: product[0].description,
        dimensions: {
          length: product[0].dimensions.length.toString(),
          width: product[0].dimensions.width.toString(),
          height: product[0].dimensions.height.toString(),
        },
        price: {
          retail: product[0].price.retail.toString(),
          wholesale: product[0].price.wholesale.toString(),
        },
        quantity: product[0].quantity.toString(),
        sizes: product[0].sizes,
        packing: {
          IC: product[0].packing.IC.toString(),
          MC: product[0].packing.MC.toString(),
        },
        customization: product[0].customization,
        productType: product[0].productType,
        imageData: [],
        imageUrls: [],
        nonRemovedImageUrls: product[0].image_url?.images
          ? product[0].image_url.images
          : [],
        qrCodeImageUrl: product[0].qrCodeImageUrl,
      };
      await axios.put(
        `${process.env.REACT_APP_API_URL}/update-product-details/${productId}`,
        updatedProd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMsg('Product Details Updated Successfully');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSuccessMsg(null);
      }, 3000);
      getAllProducts();
    } catch (error) {
      setErrorMsg('Failed to Update Product Details');
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg(null);
      }, 3000);
    }
  };

  const removeColor = async (productId, index) => {
    try {
      const product = await getProductByID(productId);

      const output = {
        color: {
          item_color: [],
          color_finish: [],
        },
      };
      product[0].color.forEach((item) => {
        output.color.item_color.push(item.item_color);
        output.color.color_finish.push(item.color_finish);
      });

      output.color.item_color.splice(index, 1);
      output.color.color_finish.splice(index, 1);

      const updatedData = {
        SK: product[0].SK,
        newSK: product[0].SK,
        newPK: product[0].PK,
        CBM: product[0].CBM.toString(),
        masterCbm: product[0].masterCbm.toString(),
        cbmValue: product[0].cbmValue.toString(),
        pcsPer20Feet: product[0].pcsPer20Feet.toString(),
        color: {
          item_color: output.color.item_color,
          color_finish: output.color.color_finish,
        },
        description: product[0].description,
        dimensions: {
          length: product[0].dimensions.length.toString(),
          width: product[0].dimensions.width.toString(),
          height: product[0].dimensions.height.toString(),
        },
        price: {
          retail: product[0].price.retail.toString(),
          wholesale: product[0].price.wholesale.toString(),
        },
        quantity: product[0].quantity.toString(),
        sizes: product[0].sizes,
        packing: {
          IC: product[0].packing.IC.toString(),
          MC: product[0].packing.MC.toString(),
        },
        customization: product[0].customization,
        productType: product[0].productType,
        imageData: [],
        imageUrls: [],
        nonRemovedImageUrls: product[0].image_url?.images
          ? product[0].image_url.images
          : [],
        qrCodeImageUrl: product[0].qrCodeImageUrl,
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/update-product-details/${productId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMsg('Product Details Updated Successfully');
      setShowSuccess(true);
      getAllProducts();
      setTimeout(() => {
        setShowSuccess(false);
        setSuccessMsg(null);
      }, 3000);
    } catch (error) {
      setErrorMsg('Failed to Update Product Details');
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg(null);
      }, 3000);
    }
  };

  return (
    <ProductsContext.Provider
      value={{
        products,
        createProduct,
        getAllProducts,
        getProductByID,
        getProductsByType,
        updateProduct,
        addNewColor,
        removeColor,
        deleteProducts,
      }}
    >
      {showSuccess && (
        <Alert
          icon={CheckCircleIcon}
          bgColor='fixed right-0 top-0 border-2 border-green-600 m-2 rounded-md bg-green-50 p-4'
          iconColor='h-7 w-7 text-green-400'
          text={successMsg}
          buttonColor='inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50'
          icon2={XMarkIcon}
        />
      )}
      {showError && (
        <Alert
          icon={XCircleIcon}
          bgColor='fixed right-0 top-0 border-2 border-red-600 m-2 rounded-md bg-red-50 p-4'
          iconColor='h-7 w-7 text-red-400'
          text={errorMsg}
          buttonColor='inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50'
          icon2={XMarkIcon}
        />
      )}
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => useContext(ProductsContext);
