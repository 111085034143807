import React from 'react'
import ProductDetails from '../components/ProductDetails/ProductDetails'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Footer from '../components/Shared/Footer'
import Header from '../components/Shared/Header'
import InvitationCode from '../components/Shared/InvitationCode'

function ViewProductDetails() {
  return (
    <div>
      {localStorage.getItem('code') ? (
        <>
          <Header />
          <ToastContainer />
          <ProductDetails
            breadcrumb={[{ name: 'Products', url: '/products' }]}
          />
          <Footer />
        </>
      ) : (
        <InvitationCode />
      )}
    </div>
  )
}

export default ViewProductDetails
