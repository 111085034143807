import React, { createContext, useState, useContext } from 'react'
import {
  XMarkIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import axios from 'axios'
import Actions from '../components/Admin/Content/Actions'
import { AuthContext } from './AuthProvider'
import Alert from '../components/Admin/Content/Alert'

export const UsersContext = createContext()

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([])
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState(null)
  const { token } = useContext(AuthContext) // Accessing the idToken from the AuthContext

  const createUser = async (newUser) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/save`,
        newUser,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      setSuccessMsg(`New User Account Created Successfully`)
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
        setSuccessMsg(null)
      }, 3000)
      getAllUsers()
    } catch (error) {
      setErrorMsg(`Failed to Create the New User Account`)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
        setErrorMsg(null)
      }, 3000)
    }
  }
  const deleteUsers = async (userId) => {
    try {
      const user = await getUserByID(userId)

      const { SK, userName } = user[0]

      const body = {
        SK: SK,
        userName: userName,
      }

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/user/delete-user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: JSON.stringify(body),
        },
      )
      setSuccessMsg('User Account Deleted Successfully')
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
        setSuccessMsg(null)
      }, 3000)
      getAllUsers()
    } catch (error) {
      setErrorMsg('Failed to Delete the User Account')
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
        setErrorMsg(null)
      }, 3000)
      console.error(error)
    }
  }

  const getAllUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/all-users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response.data
        const usr = []
        for (let i = 0; i < data.length; i++) {
          const temp_Users = {
            PK: data[i].PK,
            SK: data[i].SK,
            firstName: data[i].firstName,
            lastName: data[i].lastName,
            DOB: data[i].DOB,
            NIC: data[i].NIC,
            contactNumber1: data[i].contactNumber1,
            contactNumber2: data[i].contactNumber2,
            email: data[i].email,
            userRole: data[i].userRole,
            actions: (
              <Actions selectedTab={1} userId={data[i].PK} name={data[i].SK} />
            ),
          }
          usr.push(temp_Users)
        }
        setUsers(usr)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getUserByID = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return response.data
    } catch (error) {}
  }

  const updateUser = async (userId, updatedData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/user/update-user/${userId}`,
        updatedData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      const updatedUser = await getUserByID(userId)

      setUsers((prevUsers) => {
        const updatedUsers = prevUsers.map((user) => {
          if (user.PK === updatedUser.PK) {
            return {
              ...user,
              authKey: updatedUser.authKey,
              userRole: updatedUser.userRole,
              userName: updatedUser.userName,
              SK: updatedUser.SK,
              firstName: updatedUser.firstName,
              lastName: updatedUser.lastName,
              NIC: updatedUser.NIC,
              DOB: updatedUser.DOB,
              email: updatedUser.email,
              address: updatedUser.address,
              contactNumber1: updatedUser.contactNumber1,
              contactNumber2: updatedUser.contactNumber2,
              password: updatedUser.password,
            }
          }
          return user
        })

        return updatedUsers
      })
      setSuccessMsg('User Account Updated Successfully')
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
        setSuccessMsg(null)
      }, 3000)
      getAllUsers()
    } catch (error) {
      setErrorMsg('Failed to Update the User Account')
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
        setErrorMsg(null)
      }, 3000)
    }
  }
  return (
    <UsersContext.Provider
      value={{
        users,
        createUser,
        getAllUsers,
        getUserByID,
        updateUser,
        deleteUsers,
      }}
    >
      {showSuccess && (
        <Alert
          icon={CheckCircleIcon}
          bgColor="fixed z-[2000] right-0 top-0 border-2 border-green-600 m-2 rounded-md bg-green-50 p-4"
          iconColor="h-7 w-7 text-green-400"
          text={successMsg}
          buttonColor="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
          icon2={XMarkIcon}
        />
      )}
      {showError && (
        <Alert
          icon={XCircleIcon}
          bgColor="fixed  z-[2000] right-0 top-0 border-2 border-red-600 m-2 rounded-md bg-red-50 p-4"
          iconColor="h-7 w-7 text-red-400"
          text={errorMsg}
          buttonColor="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
          icon2={XMarkIcon}
        />
      )}
      {children}
    </UsersContext.Provider>
  )
}

export const useUsersContext = () => useContext(UsersContext)
