import React, { useEffect, useState } from 'react';
import { useUsersContext } from '../../../../context/UserContext';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Loading from '../../Auth/Loading';

function ViewUserDetails({ userId, onClose }) {
  const [user, setUser] = useState(null);

  const { getUserByID } = useUsersContext();

  useEffect(() => {
    const fetchUser = async () => {
      const userData = await getUserByID(userId);
      setUser(userData);
    };
    fetchUser();
  }, []);

  if (!user) {
    return <Loading />;
  }
  return (
    <form className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-4xl'>
      <div className='flex justify-between text-gray-900 text-3xl px-4 sm:px-6 pb-5 pt-6'>
        <b>User Details</b>
        <XMarkIcon
          onClick={() => onClose()}
          className='h-10 w-10 text-gray-500 cursor-pointer'
        />
      </div>
      <div className='flex-1 h-screen overflow-y-auto overflow-x-hidden'>
        <div className='flex flex-1 flex-col justify-between'>
          <div className='divide-y divide-gray-200 px-4 sm:px-6'>
            <div className='space-y-6 pb-5 pt-6'>
              <div className='block max-w-md rounded-lg bg-white p-3 '>
                {user.map((u) => (
                  <div className='grid grid-cols-2 lg:gap-3  gap-3'>
                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[70%]   pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out  '>
                        First Name
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none'>
                        {u.firstName}
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none '>
                        Last Name
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none'>
                        {u.lastName}
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out'>
                        NIC
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out'>
                        {u.NIC}
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none '>
                        Date Of Birth
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none'>
                        {u.DOB}
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none '>
                        Contact Number
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%]   pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out   '>
                        {u.contactNumber1} , {u.contactNumber2}
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out '>
                        Email Address
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out  '>
                        {u.email}
                      </label>
                    </div>

                    <div className='relative h-16 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out '>
                        Address
                      </label>
                    </div>

                    <div className='relative h-16 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out   '>
                        {u.address}
                      </label>
                    </div>

                    <div className='relative  h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none '>
                        User Role
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none'>
                        {u.userRole}
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none '>
                        Username
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none'>
                        {u.userName}
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none '>
                        Password
                      </label>
                    </div>

                    <div className='relative h-12 break-words'>
                      <label className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none'>
                        authorized
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-shrink-0 justify-end px-4 py-4'>
        <button
          type='button'
          className='ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-gray-800 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default ViewUserDetails;
