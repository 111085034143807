import React from 'react'
import OrderSummary from '../components/OrderSummary/OrderSummary'
import Footer from '../components/Shared/Footer'
import Header from '../components/Shared/Header'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function ViewOrderSummary() {
  return (
    <div>
      <Header page={6} />
      <ToastContainer />
      <OrderSummary />
      <Footer />
    </div>
  )
}

export default ViewOrderSummary
