import React from 'react'
import Footer from '../components/Shared/Footer'
import Header from '../components/Shared/Header'
import Home from '../components/HomePage/Home'
import Arrivals from '../components/HomePage/Arrivals'

function ViewHome() {
  return (
    <div>
      <Header page={1} />
      <Home />
      <Arrivals />
      <Footer />
    </div>
  )
}

export default ViewHome
