import React, { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { useNavigate } from 'react-router-dom';
import transparentImage from '../../assets/emptyCart.jpeg';

function ViewProductQRScan() {
  const navigate = useNavigate();
  const [scanResult, setScanResults] = useState(null);

  useEffect(() => {
    const html5QrCode = new Html5Qrcode('reader');
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      console.log(`Code matched = ${decodedText}`, decodedResult);
      setScanResults(decodedText);
      html5QrCode.clear();
    };
    const config = { fps: 10, qrbox: { width: 200, height: 200 } };

    html5QrCode.start(
      { facingMode: 'environment' },
      config,
      qrCodeSuccessCallback
    );
  }, []);

  const onClose = () => {
    navigate('/products');
  };
  return (
    <div
      className='flex flex-col items-center justify-center min-h-screen'
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${transparentImage})`,
        backgroundSize: 'cover'
      }}>
      <div className='max-w-md w-full bg-sky-50 p-8 shadow rounded'>
        {!scanResult ? (
          <div id='reader' className='mt-4'></div>
        ) : (
          <div className='relative'>
            <div className='absolute lg:mt-2 justify-start'>
              View item at
              <br />
              <a href={scanResult}>
                <b className='text-blue-800 text-sm'>{scanResult}</b>
              </a>
            </div>
          </div>
        )}

        <div className='grid grid-cols-1 gap-4 mt-12'>
          <button
            onClick={() => onClose()}
            className='flex justify-center gap-x-2 rounded-md bg-black px-12 py-4 text-md font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 w-full lg:mt-16 mt-2'>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ViewProductQRScan;
