/* eslint-disable no-useless-escape */
import React, { useState, useCallback } from 'react'
import { useRef } from 'react'

const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const [isAuthReady, setIsAuthReady] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authorizedUser, setUser] = useState(undefined)
  const [token, setToken] = useState(undefined)

  const lastMouseDownTime = useRef(Date.now())

  const signIn = useCallback(async (user, initialMetadata) => {
    //setAccessToken(jwt);
    //setMetadata(initialMetadata);
    setToken(user.signInUserSession.idToken.jwtToken)
    setUser({
      username: user.username,
      ...user.attributes,
      authKey: user.attributes.sub,
      role: user.attributes['custom:role'],
    })
    setIsAuthenticated(true)
    setIsAuthReady(true)
  }, [])

  const signOut = useCallback(() => {
    setIsAuthReady(true)
    //clearAuthData();
    setIsAuthenticated(false)
    setUser(undefined)
    setToken(undefined)
    //window.location.href = '/login'
  }, [])

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        isAuthReady,
        isAuthenticated,
        authorizedUser,
        token,
      }}
    >
      {isAuthReady ? children : children}
    </AuthContext.Provider>
  )
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth, AuthContext }
