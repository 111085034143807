import React, { createContext, useState, useContext } from 'react'
import {
  XMarkIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import axios from 'axios'
import Actions from '../components/Admin/Content/Actions'
import { AuthContext } from './AuthProvider'
import Alert from '../components/Admin/Content/Alert'

export const FeedbacksContext = createContext()

export const FeedbacksProvider = ({ children }) => {
  const [feedbacks, setFeedbacks] = useState([])
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState(null)

  const { token } = useContext(AuthContext) // Accessing the token from the AuthContext

  const deleteFeedbacks = async (feedbackId, name) => {
    try {
      await axios.delete(
        `${
          process.env.REACT_APP_API_URL
        }/feedback/delete-feedback/${feedbackId}/${encodeURIComponent(name)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setSuccessMsg(`Feedback ${name} Deleted Successfully`)
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
        setSuccessMsg(null)
      }, 3000) // 3 seconds delay
      getAllFeedbacks()
    } catch (error) {
      setErrorMsg(`Failed to Delete Feedback ${name}`)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
        setErrorMsg(null)
      }, 3000) // 3 seconds delay
      console.error(error)
    }
  }

  const getAllFeedbacks = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/all-feedbacks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data
        const prod = []
        for (let i = 0; i < data.length; i++) {
          const temp_Prod = {
            PK: data[i].PK,
            SK: data[i].SK,
            name:
              data[i].name.length > 30
                ? `${data[i].name.slice(0, 30)}...`
                : data[i].name,
            phone: data[i].phone,
            email: data[i].email,
            subject:
              data[i].subject.length > 50
                ? `${data[i].subject.slice(0, 50)}...`
                : data[i].subject,
            message:
              data[i].message.length > 30
                ? `${data[i].message.slice(0, 30)}...`
                : data[i].message,

            actions: (
              <Actions
                selectedTab={5}
                feedbackId={data[i].PK}
                name={data[i].SK}
              />
            ),
          }
          prod.push(temp_Prod)
        }
        setFeedbacks(prod)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getFeedbackByID = async (feedbackId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/feedback/${feedbackId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      return response.data
    } catch (error) {}
  }

  return (
    <FeedbacksContext.Provider
      value={{
        feedbacks,
        getAllFeedbacks,
        getFeedbackByID,
        deleteFeedbacks,
      }}
    >
      {showSuccess && (
        <Alert
          icon={CheckCircleIcon}
          bgColor="fixed right-0 top-0 border-2 border-green-600 m-2 rounded-md bg-green-50 p-4"
          iconColor="h-7 w-7 text-green-400"
          text={successMsg}
          buttonColor="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
          icon2={XMarkIcon}
        />
      )}
      {showError && (
        <Alert
          icon={XCircleIcon}
          bgColor="fixed right-0 top-0 border-2 border-red-600 m-2 rounded-md bg-red-50 p-4"
          iconColor="h-7 w-7 text-red-400"
          text={errorMsg}
          buttonColor="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
          icon2={XMarkIcon}
        />
      )}
      {children}
    </FeedbacksContext.Provider>
  )
}

export const useFeedbacksContext = () => useContext(FeedbacksContext)
