import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useClientsContext } from '../../../../context/ClientsContext';
import Loading from '../../Auth/Loading';

function ViewClientDetails({ clientId, onClose }) {
  const [clients, setClients] = useState(null);
  const { getClientByID } = useClientsContext();

  useEffect(() => {
    const fetchClient = async () => {
      const clientData = await getClientByID(clientId);

      setClients(clientData);
    };
    fetchClient();
  }, []);
  if (!clients) {
    return <Loading />;
  }
  return (
    <form className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-4xl'>
      <div className='flex justify-between text-gray-900 text-3xl px-4 sm:px-6 pb-5 pt-6'>
        <b>Client details</b>
        <XMarkIcon
          onClick={() => onClose()}
          className='h-10 w-10 text-gray-500 cursor-pointer'
        />
      </div>
      <div className='flex-1 h-screen overflow-y-auto overflow-x-hidden'>
        <div className='flex flex-1 flex-col justify-between'>
          <div className='divide-y divide-gray-200 px-4 sm:px-6'>
            <div className='space-y-6 pb-5 pt-6'>
              {clients.map((c) => (
                <div class='grid grid-cols-2 lg:gap-3 gap-3'>
                  <div class='relative mb-4 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[70%]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out  '>
                      Buyer Type
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none'>
                      {c.buyerCategory}
                    </label>
                  </div>
                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[70%]   pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out  '>
                      First Name
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none'>
                      {c.firstName}
                    </label>
                  </div>
                  <div class='relative mb-8 w-40 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none '>
                      Last Name
                    </label>
                  </div>

                  <div class='relative mb-7 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none'>
                      {c.lastName}
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out'>
                      Company Name
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%]  pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out'>
                      {c.companyName}
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out  '>
                      Title
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out '>
                      {c.title}
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none '>
                      Contact Numbers
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%]   pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out   '>
                      {c.contactNumber1}, {c.contactNumber2}
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out '>
                      Email Address
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] pt-[0.37rem] leading-[1.6] text-gray-700 transition-all duration-200 ease-out  '>
                      {c.email}
                    </label>
                  </div>

                  <div class='relative mb-2 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out '>
                      Web Site
                    </label>
                  </div>

                  <div class='relative mb-8 w-75 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] text-gray-700  ease-out   '>
                      {c.website}
                    </label>
                  </div>

                  <div class='relative mb-8 py-2 break-words'>
                    <label class='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0]  pt-[0.37rem] leading-[1.6] text-neutral-700 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none '>
                      Business Card
                    </label>
                  </div>

                  <div class='relative mb-8 py-2'>
                    <div class='flex items-start justify-start '>
                      <img
                        src={c.businessCardUrl}
                        alt='business_card'
                        className='rounded-xl'
                        width={100}
                        height='10'
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-shrink-0 justify-end px-4 py-4'>
        <button
          type='button'
          className='ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-gray-800 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default ViewClientDetails;
