import React from 'react';

const ImageCard = ({image}) => {
    return (
        <div className="w-48 rounded overflow-hidden shadow-lg">
            <img src={image} className="w-full"/>
        </div>
    );
};

export default ImageCard;
