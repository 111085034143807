import React from 'react'
import RegistrationForm from '../components/Registration/RegistrationForm'
import Footer from '../components/Shared/Footer'

function ViewRegistration() {
  return (
    <div>
      <RegistrationForm />
    </div>
  )
}

export default ViewRegistration
