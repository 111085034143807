import React from 'react'
import Form from '../components/ContactUs/Form'
import Heading from '../components/Shared/Heading'
import Footer from '../components/Shared/Footer'
import Header from '../components/Shared/Header'

function ViewContactUs() {
  return (
    <div>
      <Header page={4} />

      <Heading
        heading="Contact Us"
        breadcrumb={[{ name: 'Contact Us', url: '/contactus' }]}
      />
      <Form />
      <Footer />
    </div>
  )
}

export default ViewContactUs
