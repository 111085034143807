import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AnalyticsAPI } from '../../apis/analyticsAPI'
import axios from "axios";

function Arrivals() {
  const [pro, setPro] = useState([])
  // const [sessionID, setData] = useState([]);

  const getSessionID = () => {
    try {
      fetch(`${process.env.REACT_APP_USER_SESSION}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'x-api-key': process.env.REACT_APP_XAPI_KEY,
        },
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          // setData(data);
          localStorage.setItem('sessionId', data.sessionId)
        })
        .catch((error) => {})
    } catch (error) {}
  }
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }
  useEffect(() => {
    if ('sessionId' in localStorage) {
      const body = {
        state: 'start Home Page',
        hasPurchasedItems: false,
        hasVisited: window.innerWidth <= 768 ? "exhibition" : "website",
        recordedDateTime: (new Date()).toISOString(),
      }
      AnalyticsAPI.getUserState(
        body,
      )
        .then((response) => {})
        .catch((error) => {})
    } else {
      getSessionID()
    }

    async function fetchData() {
      try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/products-by-type/new`,
            {
              headers: {'x-api-key': process.env.REACT_APP_X_API_KEY},
            },
        )
        let data = response.data.filter(
            (element) =>
                element.image_url?.images?.length > 0 &&
                !element.image_url?.images[0].includes("no-image") &&
                element.image_url?.images[0] != ""
        );
        data = shuffle(data).slice(0, 4);
        setPro(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [])
  return (
    <div>
      <div className="bg-gray-100 flex flex-col justify-center items-center pt-9 sm:pt-12 lg:pt-16 pb-16 sm:pb-32">
        <div className="2xl:container 2xl:mx-auto flex flex-col justify-center items-center sm:pb-12 lg:pb-0 space-y-4 px-4 md:px-6 2xl:px-0">
          <div>
            <p className="text-3xl lg:text-4xl font-semibold leading-9 text-center text-gray-800">
              New Arrivals
            </p>
          </div>
          <div>
            <p className="text-base leading-normal sm:leading-none text-center text-gray-600">
              Own A Piece Of An Artist
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-items-between gap-x-6 gap-y-5 mt-24 lg:mx-24 md:mx-16 sm:mx-8 mx-8">
          {pro.map((p) => (
            <div key={p.PK} className="group">
              <Link to={'/products/' + p.PK}>
                <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
                  <img
                    src={
                      p?.image_url?.images.length > 0
                        ? p?.image_url?.images[0]
                        : ''
                    }
                    alt={p.SK}
                    className="lg:h-80 lg:w-80  md:h-50 md:w-50 h-80 w-80 object-cover object-center group-hover:opacity-75"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">{p.SK}</h3>
              </Link>
            </div>
          ))}
        </div>
        <div className=" flex justify-center items-center">
          <Link to="/arrivals">
            <button className=" hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 bg-gray-800 py-5 md:px-12 px-12 md:w-auto w-full lg:mt-16 md:mt-12 mt-10 text-white font-medium text-base leading-4">
              View More
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Arrivals
