import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import Alert from '../../../../components/Admin/Content/Alert'
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
function ChangePassword() {
  const navigate = useNavigate()

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const updatePassword = async (event) => {
    event.preventDefault()
    setSuccessMessage(null) // Reset success message
    setErrorMessage(null) // Reset error message
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword, confirmPassword)
        })
        .then((data) => {
          setTimeout(() => {
            setSuccessMessage('Password updated successfully!')
          }, 3000) // 3 seconds delay
        })
        .catch((err) => {
          if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match. Please try again.')
          } else {
            setErrorMessage(
              'Old Password, New Password, Confirm New Password Fields Cannot Be Empty',
            )
          }
        })
    } catch (error) {}
  }

  return (
    <section className="container px-4 lg:py-8 md:py-4 sm:py-4 py-4 mx-auto">
      {successMessage && (
        <Alert
          icon={CheckCircleIcon}
          bgColor="relative border-2 border-green-600 m-2 rounded-md bg-green-50 p-4"
          iconColor="h-7 w-7 text-green-400"
          text={successMessage}
          buttonColor="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
          icon2={XMarkIcon}
        />
      )}
      {errorMessage && (
        <Alert
          icon={XCircleIcon}
          bgColor="relative border-2 border-red-600 m-2 rounded-md bg-red-50 p-4"
          iconColor="h-7 w-7 text-red-400"
          text={errorMessage}
          buttonColor="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
          icon2={XMarkIcon}
        />
      )}
      <div className="flex flex-col lg:mx-auto">
        <form
          action="#"
          method="POST"
          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-4xl overflow-y-auto"
        >
          <div className="h-0 flex-1">
            <div className="flex flex-1 flex-col justify-between">
              <div className="divide-y divide-gray-200 px-4 sm:px-6">
                <div className="space-y-6 pb-5">
                  <div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-2 ">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="oldPassword"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Old password
                        </label>
                        <div className="mt-2">
                          <input
                            type="password"
                            name="oldPassword"
                            id="oldPassword"
                            required
                            value={oldPassword}
                            onChange={(event) =>
                              setOldPassword(event.target.value)
                            }
                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="confirmPassword"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          New password
                        </label>
                        <div className="mt-2">
                          <input
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            required
                            value={newPassword}
                            onChange={(event) =>
                              setNewPassword(event.target.value)
                            }
                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="confirmPassword"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Confirm new password
                        </label>
                        <div className="mt-2">
                          <input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            required
                            value={confirmPassword}
                            onChange={(event) =>
                              setConfirmPassword(event.target.value)
                            }
                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-shrink-0 justify-end mt-10 border-t border-gray-900/10  px-4 py-4">
                    <button
                      type="submit"
                      onClick={updatePassword}
                      className="ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-blue-800 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default ChangePassword
