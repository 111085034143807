import React, { useState, useEffect } from "react";
import Dashboard from "../../components/Admin/Dashboard/Dashboard";
import Actions from "../../components/Admin/Content/Actions";
import Color from "../../components/Admin/Content/Color";
import { useProductsContext } from "../../context/ProductsContext";

function ViewAdminQRGenerator() {
  const { products, getAllProducts, getProductsByType } = useProductsContext();
  const [tableData,setTableData]= useState([])
  const [selectedType, setSelectedType] = useState(null);

  const columns = [
    { name: "ITEM ID", selector: "PK" },
    { name: "ITEM NAME", selector: "SK" },
    { name: "DIMENSIONS (L X H X W)", selector: "dimensions" },
    { name: "DEFAULT COLORS", selector: "colors" },
    { name: "ACTIONS", selector: "actions" },
  ];


  useEffect( () => {
    getAllProducts();
    setTableData(products)
  }, []);

  useEffect(() => {
    const prod = [];
    const data = products;
    for (let i = 0; i < data.length; i++) {
      const temp_Prod = {
        PK: data[i].PK,
        SK: data[i].SK,
        dimensions: data[i].dimensions,
        colors: data[i].colors.props.color ? (
          <Color color={data[i].colors.props.color} />
        ) : null,
        actions: (
          <Actions selectedTab={6} name={data[i].SK}  colors={data[i].colors.props.color ? data[i].colors.props.color : null} productId={data[i].PK} productQR={data[i].qrCodeImageUrl}/>
        ),
      };
      prod.push(temp_Prod);
    }
    setTableData(prod)
  }, [products]);

  function handleSearch(e) {
    if (e.target.value === "") {
      if (selectedType === 'all' || !selectedType) {
        getAllProducts();
      } else {
        getProductsByType(selectedType);
      }
    }

    const filteredData = products.filter((row) => {
      return row["PK"]
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    const prod = [];
    const data = filteredData;
    for (let i = 0; i < data.length; i++) {
      const temp_Prod = {
        PK: data[i].PK,
        SK: data[i].SK,
        dimensions: data[i].dimensions,
        colors: data[i].colors.props.color ? (
          <Color color={data[i].colors.props.color} />
        ) : null,
        actions: (
          <Actions selectedTab={6} name={data[i].SK}  colors={data[i].colors.props.color ? data[i].colors.props.color : null} productId={data[i].PK} productQR={data[i].qrCodeImageUrl}/>
        ),
      };
      prod.push(temp_Prod);
    }
    setTableData(prod);
  }

  const handleFilter = (type) => {
    setSelectedType(type);
    if (type === 'all') {
      getAllProducts();
    } else {
      getProductsByType(type);
    }
  };

  return (
    <div>
      <Dashboard
        tab={6}
        title="QR Generator"
        search="Search products..."
        columns={columns}
        data={tableData}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        selectedType={selectedType}
      />
    </div>
  );
}

export default ViewAdminQRGenerator;
