import { Amplify } from 'aws-amplify'
import { AuthProvider } from './context'
import Routes from './Routes'

function App() {
  const config = {
    Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    },
  }

  Amplify.configure(config)
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  )
}

export default App
