import React, { createContext, useState, useContext } from 'react';
import {
  XMarkIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import Actions from '../components/Admin/Content/Actions';
import { AuthContext } from './AuthProvider';
import Alert from '../components/Admin/Content/Alert';
import Status from '../components/Admin/Content/Status';

export const QuotationsContext = createContext();

export const QuotationsProvider = ({ children }) => {
  const [quotations, setQuotations] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);

  const { token } = useContext(AuthContext); // Accessing the token from the AuthContext

  const getAllQuotations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/all-quotations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;

      const quotation = [];
      for (let i = 0; i < data.length; i++) {
        var qty = 0;
        var value = 0.0;
        for (let j = 0; j < data[i].products.length; j++) {
          qty += data[i].products[j].qty;
          value += data[i].products[j].totalValue;
        }
        const temp_Prod = {
          PK: data[i].PK,
          SK: data[i].SK,
          quotationNumber: data[i].quotationNumber,
          quotationReceivedDate: data[i].quotationReceivedDate,
          customerName: data[i].customerName,
          companyName: data[i].companyName,
          shippingFrom: 'Colombo',
          num_of_pcs: qty,
          invoice_value: value.toFixed(2),
          quoteStatus: data[i].GSI1PK ? (
            <Status status={data[i].GSI1PK.split('#')[1]} />
          ) : null,

          actions: (
            <Actions
              selectedTab={2}
              qutationId={data[i].PK}
              name={data[i].SK}
              status={data[i].GSI1PK.split('#')[1]}
            />
          ),
        };
        quotation.push(temp_Prod);
      }
      setQuotations(quotation);
    } catch (error) {
      console.error(error);
    }
  };

  const getQuotationByID = async (quotationId, name) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/quotation/${encodeURIComponent(
          quotationId
        )}/${encodeURIComponent(name)}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {}
  };

  const getProductDetails = async (productId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${productId}`,
        {
          headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY },
        }
      );
      return response.data[0];
    } catch (error) {}
  };

  const approveQuotation = async (QuotationDetails) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/send-quotation`,
        QuotationDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMsg(`Quotation Approved Successfully`);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSuccessMsg(null);
      }, 3000);
      getAllQuotations();
    } catch (error) {
      setErrorMsg(`Failed to Approve the Quotation`);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg(null);
      }, 3000);
    }
  };

  const updateQuotation = async (quotation) => {
    try {
      const updateObject = {
        SK: quotation.SK,
        PK: quotation.PK,
        customerName: quotation.customerName,
        companyName: quotation.companyName,
        quotationNumber: quotation.quotationNumber,
        pdfS3Key: quotation.pdfS3Key,
        quoteStatus: quotation.GSI1PK.split('#')[1],
        customerEmail: quotation.GSI1SK,
        quotationReceivedDate: quotation.quotationReceivedDate,
        products: quotation.products,
        sentOn: new Date().toISOString(),
        GSI1PK: quotation.GSI1PK,
        GSI1SK: quotation.GSI1SK,
      };
      await axios.put(
        `${
          process.env.REACT_APP_API_URL
        }/update-quotation-details/${encodeURIComponent(quotation.PK)}`,
        updateObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      setErrorMsg(`Failed to update the Quotation`);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg(null);
      }, 3000);
      throw error;
    }
  };

  return (
    <QuotationsContext.Provider
      value={{
        quotations,
        getAllQuotations,
        getQuotationByID,
        approveQuotation,
        getProductDetails,
        updateQuotation,
      }}
    >
      {showSuccess && (
        <Alert
          icon={CheckCircleIcon}
          bgColor='fixed right-0 top-0 border-2 border-green-600 m-2 rounded-md bg-green-50 p-4'
          iconColor='h-7 w-7 text-green-400'
          text={successMsg}
          buttonColor='inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50'
          icon2={XMarkIcon}
        />
      )}
      {showError && (
        <Alert
          icon={XCircleIcon}
          bgColor='fixed right-0 top-0 border-2 border-red-600 m-2 rounded-md bg-red-50 p-4'
          iconColor='h-7 w-7 text-red-400'
          text={errorMsg}
          buttonColor='inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50'
          icon2={XMarkIcon}
        />
      )}
      {children}
    </QuotationsContext.Provider>
  );
};

export const useQuotationContext = () => useContext(QuotationsContext);
