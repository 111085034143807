import React, { useState, useEffect } from "react";
import { DocumentArrowUpIcon, XMarkIcon } from "@heroicons/react/24/outline";
import * as Yup from "yup";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProductsContext } from "../../../../context/ProductsContext";
import Loading from "../../Auth/Loading";
import ButtonLoader from "../../../../components/Shared/ButtonLoader";
import ImageWithDelete from "../../../../components/Shared/ImageWithDelete";

function ViewEditProduct({ productId, onClose }) {
  const { getProductByID, updateProduct } = useProductsContext();
  const [productData, setproductData] = useState(null);
  const [images, setImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  /**
   * manage image removal
   * @param index
   */
  const onDelete = (index) =>{
    const newItem = JSON.parse(JSON.stringify(images[index]));
    const newItems = [...removedImages, newItem];
    setValue('imageUrls', newItems);
    setRemovedImages(newItems);
    const updatedItems =  JSON.parse(JSON.stringify(images)); // Create a copy of the array
    updatedItems.splice(index, 1); // Remove the item at the specified index
    setImages(updatedItems);
    setValue('nonRemovedImageUrls', updatedItems);
  }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await updateProduct(productId, data);
      setIsLoading(false);
      reset();
      onClose();
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileChange = (event) => {
    const files = event.target.files;
    const imageFiles = [];
    let fileNames = '';

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.startsWith('image/')) {
        imageFiles.push(file);
        fileNames += (file ? file.name : '') +  ', ';
      }
    }

    let modifiedStr = fileNames.trim().slice(0, -1);
    setSelectedFile(modifiedStr);
    readImages(imageFiles);
  };

  const readImages = (files) => {
    const imagePromises = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Create a FileReader instance
      const reader = new FileReader();

      imagePromises.push(
          new Promise((resolve) => {
            // Read the file as data URL
            reader.readAsDataURL(file);

            // Set up the onload event listener
            reader.onload = (event) => {
              const imageDataURL = event.target.result;
              resolve(imageDataURL);
            };
          })
      );
    }

    Promise.all(imagePromises).then((imageDataURLs) => {
      setValue("imageData", imageDataURLs);
    });
  };

  const productDetailsSchema = Yup.object().shape({
    SK: Yup.string().required("Item Name is required"),
    dimensions: Yup.object().shape({
      height: Yup.number().positive().required("Height is required"),
      width: Yup.number().positive().required("Width is required"),
      length: Yup.number().positive().required("Length is required"),
    }),
    CBM: Yup.string().required("CBM is required"),
    masterCbm:Yup.string().required("Master CBM is required"),
    cbmValue:Yup.string().required("CBM Value is required"),
    sizes: Yup.string().required("Size is required"),
    pcsPer20Feet:Yup.string().required("Pcs Per 20 Feet is required"),

    quantity: Yup.number()
      .positive()
      .required("Available Quantity is required"),
    description: Yup.string().required("Description is required"),
    price: Yup.object().shape({
      retail: Yup.number().positive().required("Retail Price is required"),
      wholesale: Yup.number()
        .positive()
        .required("Wholesale Price is required"),
    }),
    packing: Yup.object().shape({
      IC: Yup.string().required("IC is required"),
      MC: Yup.string().required("MC is required"),
    }),
    // imageData: Yup.mixed()
    //   .required('Product Image is required')
    //   .test('fileType', 'Unsupported file type', (value) => {
    //     if (value && value.length > 0 && value[0].type) {
    //       return value[0].type.startsWith('image/')
    //     }
    //     return true // Skip the test if value is undefined, empty, or type is undefined
    //   }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(productDetailsSchema),
    defaultValues: async () => {
      const products = await getProductByID(productId);
      return {
        SK: products[0].SK.toString().trim(),
        newSK: products[0].SK.toString().trim(),
        newPK: productId.toString().trim(),
        CBM: products[0].CBM.toString().trim(),
        masterCbm: products[0].masterCbm.toString().trim(),
        cbmValue:products[0].cbmValue.toString().trim(),
        color: products[0].color,
        description: products[0].description,
        dimensions: {
          height: products[0].dimensions.height,
          width: products[0].dimensions.width,
          length: products[0].dimensions.length,
        },
        price: {
          retail: products[0].price.retail,
          wholesale: products[0].price.wholesale,
        },
        quantity: products[0].quantity,
        sizes: products[0].sizes.toString().trim(),
        pcsPer20Feet: products[0].pcsPer20Feet.toString().trim(),
        packing: {
          IC: products[0].packing.IC,
          MC: products[0].packing.MC,
        },
        customization: products[0].customization,
        productType: products[0].product_type,
        qrCodeImageUrl: products[0].qrCodeImageUrl,
        imageUrls: [],
        imageData: [],
        nonRemovedImageUrls: products[0].image_url?.images ? products[0].image_url.images: []
      };
    },
    mode: "all",
  });

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const products = await getProductByID(productId);
        setproductData(products[0]);
        setImages( products[0].image_url?.images ? JSON.parse(JSON.stringify(products[0].image_url.images)): [])
      } catch (error) {}
    };

    fetchProductData();
  }, [productId, getProductByID]);
  if (!productData) {
    return <Loading />;
  }
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex h-screen flex-col divide-y divide-gray-200 bg-white shadow-4xl overflow-y-auto"
      >
        <div className="h-0 flex-1">
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5 pt-6">
                <div className="flex justify-between border-b text-gray-900 text-3xl">
                  <b>Edit Product Details</b>
                  <XMarkIcon
                    onClick={() => onClose()}
                    className="h-10 w-10 text-gray-500 cursor-pointer"
                  />
                </div>

                <div>
                  <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="xl:col-span-full md:col-span-full lg:col-span-full col-span-2">
                      <label
                        htmlFor="details"
                        className="block  text-gray-900 text-xl"
                      >
                        Product Details
                      </label>
                      <hr />
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="productType"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Product Type
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="productType"
                          disabled
                          {...register("productType")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="xl:col-span-full md:col-span-full lg:col-span-full col-span-2">
                      <label
                        htmlFor="PK"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Product ID
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="PK"
                          disabled
                          defaultValue={productId}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="xl:col-span-full md:col-span-full lg:col-span-full col-span-2">
                      <label
                        htmlFor="newSK"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Product Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="newSK"
                          {...register("newSK")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.newSK?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-full">
                      <label
                          htmlFor="productImages"
                          className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Product Images
                      </label>
                    </div>
                    <div className="col-span-full">
                    <div className='grid grid-cols-4 gap-4'>
                      { images.map((image, i) => (
                          <div className=" relative flex space-x-4" key={i}>
                            <ImageWithDelete imageUrl={image}  onDelete={() => onDelete(i)}></ImageWithDelete>
                          </div>
                      ) )}
                    </div>
                    </div>
                    <div className="xl:col-span-full md:col-span-full lg:col-span-full col-span-2">
                      <label
                        htmlFor="imageData"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Product Images
                        <label className="text-xs text-gray-600">
                          (Upload Images: Jpg,Png)
                        </label>
                      </label>
                      <div className="mt-2 flex rounded-md shadow-sm">
                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                          <input
                            type="text"
                            id="imageData"
                            value={selectedFile}
                            readOnly
                            className="block w-full rounded-none rounded-l-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <label className="relative cursor-pointer">
                          <input
                            id="imageData"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            className="sr-only"
                            onChange={handleFileChange}
                          />
                          <div className="flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            <DocumentArrowUpIcon
                              className="-ml-0.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Choose Files</span>
                          </div>
                        </label>
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.imageData?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-full md:col-span-full lg:col-span-full col-span-2">
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Description
                      </label>
                      <div className="mt-2">
                        <textarea
                          type="text"
                          cols={25}
                          rows={5}
                          id="description"
                          {...register("description")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.description?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <label className="text-base font-semibold text-gray-900">
                        Customization
                      </label>
                      <fieldset className="mt-4">
                        <label className="relative inline-flex items-center mb-5 cursor-pointer">
                          <input type="checkbox" value="" className="sr-only peer" {...register("customization")}/>
                          <div
                              className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      </fieldset>
                      <div>
                        <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                          {errors.customization?.message}
                        </p>
                      </div>
                    </div>
                    <div className="xl:col-span-full md:col-span-full lg:col-span-full col-span-2">
                      <label
                        htmlFor="dimensions"
                        className="block  text-gray-900 text-xl"
                      >
                        Dimensions
                      </label>
                      <hr />
                    </div>
                    <div className="xl:col-span-2 md:col-span-2 lg:col-span-2  col-span-full sm:col-start-1">
                      <label
                        htmlFor="height"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Height
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="height"
                          step="0.1"
                          {...register("dimensions.height")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.dimensions?.height?.message}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="width"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Width
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="width"
                          step="0.1"
                          {...register("dimensions.width")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.dimensions?.width?.message}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="length"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Length
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="length"
                          step="0.1"
                          {...register("dimensions.length")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.dimensions?.length?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="CBM"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        CBM
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="CBM"
                          {...register("CBM")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.CBM?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="masterCbm"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                       Master CBM
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="masterCbm"
                          {...register("masterCbm")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                          {errors.masterCbm?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="cbmValue"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        CBM Value
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="cbmValue"
                          {...register("cbmValue")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                          {errors.cbmValue?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="sizes"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        sizes
                      </label>
                      <div className="mt-2 ">
                        <input
                          type="text"
                          id="sizes"
                          {...register("sizes")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.sizes?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="pcsPer20Feet"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                       Pcs Per 20 Feet
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="pcsPer20Feet"
                          {...register("pcsPer20Feet")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                          {errors.pcsPer20Feet?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-full">
                      <label
                        htmlFor="packing"
                        className="block  text-gray-900 text-xl"
                      >
                        Packing
                      </label>
                      <hr />
                    </div>
                    <div className="xl:col-span-2 md:col-span-2 lg:col-span-2 col-span-full">
                      <label
                        htmlFor="IC"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        IC
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="IC"
                          {...register("packing.IC")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.packing?.IC?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="MC"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        MC
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="MC"
                          {...register("packing.MC")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.packing?.MC?.message}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="quantity"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Available Quantity
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="quantity"
                          {...register("quantity")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.quantity?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-full md:col-span-full lg:col-span-full col-span-2">
                      <label
                        htmlFor="price"
                        className="block  text-gray-900 text-xl"
                      >
                        Price
                      </label>
                      <hr />
                    </div>

                    <div className="xl:col-span-2 col-span-2">
                      <label
                        htmlFor="wholesale"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Wholesale Price
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="wholesale"
                          {...register("price.wholesale")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.price?.wholesale?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-2 col-span-2">
                      <label
                        htmlFor="retail"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Retail Price
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="retail"
                          {...register("price.retail")}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <div>
                          <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                            {errors.price?.retail?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end mt-10 border-t border-gray-900/10  px-4 py-4">
                    <button
                      type="button"
                      className="ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-gray-800 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={ !isValid || isSubmitting}
                      type="submit"
                      className={`ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-blue-800 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ${
                         !isValid || isSubmitting
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      <ButtonLoader isLoading={isLoading}></ButtonLoader>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ViewEditProduct;
