import React, { useState, useEffect } from 'react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/20/solid';
import DashboardTab from './DashboardTab';
import DashboardTitle from './DashboardTitle';
import Table from '../Content/Table';
import Summary from '../Stattistics/Summary';
import ChangePassword from '../../../Views/AdminFeatures/Auth/Settings/ChangePassword';

function Dashboard(props) {
  const [show, setShow] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const tableData = props.data;
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(tableData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(tableData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, tableData]);

  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % tableData.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className='w-full h-full bg-white'>
        <div className='flex flex-no-wrap'>
          {/* Sidebar starts */}
          <div className='absolute lg:relative w-72 h-screen bg-white hidden lg:block'>
            <div className='h-16 w-full flex items-center px-4 mt-8 '>
              <h1 className='text-gray-800'>
                <img
                  className='w-16'
                  src='https://adi-images.s3.us-east-2.amazonaws.com/images/logo.JPG'
                  alt='logo'
                />
                <span className='block self-center text-sm font-base whitespace-nowrap text-blue-900'>
                  Art Decoration International (Pvt) Ltd
                </span>
              </h1>
            </div>
            <DashboardTab selectedTab={props.tab} />
          </div>
          {/*Mobile responsive sidebar*/}
          <div
            className={
              show
                ? 'w-full h-full absolute z-40  transform  translate-x-0'
                : 'w-full h-full absolute z-40  transform -translate-x-full'
            }
            id='mobile-nav'
          >
            <div
              className='bg-gray-800 opacity-50 absolute h-full w-full lg:hidden'
              onClick={() => setShow(!show)}
            />
            <div className='absolute z-40 sm:relative w-64 md:w-96 shadow pb-4 bg-white lg:hidden transition duration-150 ease-in-out h-full'>
              <div className='flex flex-col justify-between h-full w-full'>
                <div>
                  <div className='flex items-center justify-between px-4 '>
                    <div className='h-12 w-full flex items-center mt-8'>
                      <h1 className='text-gray-800'>
                        <img
                          className='w-12'
                          src='https://adi-images.s3.us-east-2.amazonaws.com/images/logo.JPG'
                          alt='logo'
                        />
                        <span className='block self-center text-xs font-base whitespace-nowrap text-blue-900'>
                          Art Decoration International (Pvt) Ltd
                        </span>
                      </h1>
                    </div>
                    <div
                      id='closeSideBar'
                      className='flex items-center justify-center h-10 w-10'
                      onClick={() => setShow(!show)}
                    >
                      <XMarkIcon className='h-6 w-6 cursor-pointer' />
                    </div>
                  </div>
                  <DashboardTab selectedTab={props.tab} />
                </div>
              </div>
            </div>
          </div>
          {/*Mobile responsive sidebar*/}
          {/* Sidebar ends */}
          <div className='w-full bg-blue-50 my-4 lg:mr-4 rounded-tr-3xl rounded-br-3xl'>
            {/* Navigation starts */}
            <nav className='lg:hidden h-12 flex items-center lg:items-stretch justify-end lg:justify-between bg-white shadow relative z-10'>
              <div
                className='text-gray-600 mr-8 visible lg:hidden relative'
                onClick={() => setShow(!show)}
              >
                {show ? (
                  ' '
                ) : (
                  <Bars3Icon className='h-6 w-6 hover:cursor-pointer' />
                )}
              </div>
            </nav>
            {/* Navigation ends */}
            <div
              className={`container mx-auto lg:py-10 py-8 lg:px-8 md:px-6 sm:px-4 px-4 w-full `}
            >
              <DashboardTitle
                selectedTab={props.tab}
                title={props.title}
                search={props.search}
                button={props.button}
                data={props.data}
                viewSlide={props.viewSlide}
                handleSearch={props.handleSearch}
              />
              <div className='w-full h-full lg:my-8 md:my-6 sm:my-5 my-5 rounded-2xl bg-white border-gray-300'>
                {props.tab === 1 ||
                props.tab === 2 ||
                props.tab === 3 ||
                props.tab === 4 ||
                props.tab === 5 ||
                props.tab === 6 ? (
                  <Table
                    selectedTab={props.tab}
                    title={props.title}
                    columns={props.columns}
                    data={props.data}
                    currentItems={currentItems}
                    handleFilter={props.handleFilter}
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                  />
                ) : props.tab === 7 ? (
                  <div className='w-full h-full lg:my-8 md:my-6 sm:my-5 my-5 rounded-2xl bg-white border-gray-300'>
                    <Summary />
                  </div>
                ) : props.tab === 8 ? (
                  <div className='w-full h-full lg:my-8 md:my-6 sm:my-5 my-5 rounded-2xl bg-white border-gray-300'>
                    <ChangePassword />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
