import React, { useState, useEffect } from 'react';
import BreadCrumb from '../Shared/BreadCrumb';
import ProductImgs from './ProductImgs';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../features/cartSlice';
import { useParams } from 'react-router';
import { AnalyticsAPI } from '../../apis/analyticsAPI';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { DevicePhoneMobileIcon, PlusIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ProductDetails(props) {
  const [count, setCount] = useState(1);
  const [colorNotSelected, setColorNotSelected] = useState(false);
  const [colour, setColour] = useState([]);
  const [notes, setNotes] = useState('');
  const [customization, setCustomization] = useState('');
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState('Select the Color');
  const navigate = useNavigate();

  //color index
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);

  const params = useParams();
  const dispatch = useDispatch();
  const addCount = () => {
    setCount((prev) => prev + 1);
  };

  const minusCount = () => {
    if (count > 1) {
      setCount((prev) => prev - 1);
    }
  };

  const [pro, setPro] = useState([]);
  useEffect(() => {
    callAnalyticAPI('On Product (' + `${params.prodid}` + ') Details Page');
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/${params.prodid}`,
          {
            headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY },
          }
        );
        setPro(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  //color index
  const handleColorChange = (index) => {
    console.log(index);
    setSelectedColorIndex(index);
    console.log(setSelectedColorIndex(index));
  };

  const handleAddToCart = (product) => {
    if (!colour.length) {
      console.log(customization);
      if (customization === '') {
        setColorNotSelected(true);
      } else {
        const prod = {
          ...product,
          colorRef: colour,
          customization: customization,
          remarks: notes,
          qty: count,
        };
        dispatch(addToCart(prod));
        setCount(1);
        setColour([]);
        setNotes('');
      }
    } else {
      const prod = {
        ...product,
        colorRef: colour,
        customization: customization,
        remarks: notes,
        qty: count,
      };
      dispatch(addToCart(prod));
      setCount(1);
      setColour([]);
      setNotes('');
      setCustomization('');
      setFilter('Select the Color');
    }
  };

  function callAnalyticAPI(status) {
    const body = {
      state: status,
      hasPurchasedItems: false,
      hasVisited: window.innerWidth <= 768 ? 'exhibition' : 'website',
      recordedDateTime: new Date().toISOString(),
    };
    AnalyticsAPI.getUserState(body)
      .then((response) => {})
      .catch((error) => {});
  }
  const handleAddNewItem = () => {
    navigate('/scan-qr');
  };
  /**
   * return no-image if the images are missing in the product
   * @param images
   * @returns {string[]|*}
   */
  const getImages = (images) => {
    if (images && images.length > 0) {
      return images;
    } else {
      return [
        'https://adi-images.s3.us-east-2.amazonaws.com/products/images/no-image.jpeg',
      ];
    }
  };

  return (
    <div className='mx-auto lg:py-16 lg:px-8 md:py-8 md:px-2 py-9 px-2'>
      <div className='px-8'>
        <BreadCrumb path={props.breadcrumb} />
      </div>
      {pro.map((p) => (
        <div
          key={p.PK}
          className='flex  justify-center items-center lg:flex-row flex-col gap-4 my-8'
        >
          <div className='lg:mx-auto lg:container flex justify-center'>
            {selectedColorIndex !== null ? (
              <div className=''>
                <div className='lg:px-2 px-4 py-4 w-full'>
                  <CarouselProvider
                    className='relative'
                    naturalSlideWidth={100}
                    isIntrinsicHeight={true}
                    totalSlides={p.image_url.images.length}
                    visibleSlides={1}
                    step={1}
                    infinite={true}
                    currentSlide={selectedColorIndex}
                  >
                    <div className='js-flickity flex justify-center items-center'>
                      <ButtonBack
                        role='button'
                        aria-label='slide backward'
                        className='w-12 h-12 md:w-14 md:h-14 sm:w-10 sm:h-10 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-gray-200 absolute z-20 left-0 lg:ml-8 md:ml-4 sm:-ml-4 -ml-4 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer'
                        id='prev'
                      >
                        <svg
                          width={8}
                          height={14}
                          viewBox='0 0 8 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7 1L1 7L7 13'
                            stroke='black'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </ButtonBack>
                      <Slider>
                        {p.image_url.images.map((image, index) => (
                          <Slide key={index} index={index}>
                            <div className='lg:px-32 md:px-24 sm:px-2 px-2 w-full'>
                              <div className='relative w-full h-full'>
                                <img
                                  src={image}
                                  alt={`Color ${index}`}
                                  className='object-center object-cover h-full'
                                />
                              </div>
                            </div>
                          </Slide>
                        ))}
                      </Slider>
                      <ButtonNext
                        role='button'
                        aria-label='slide forward'
                        className='w-12 h-12 md:w-14 md:h-14 sm:w-10 sm:h-10 rounded-full flex justify-center items-center bg-white border border-gray-300 hover:bg-gray-200 absolute z-20 right-0 lg:mr-8 md:mr-4 sm:-mr-4 -mr-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800'
                        id='next'
                      >
                        <svg
                          width={8}
                          height={14}
                          viewBox='0 0 8 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1 1L7 7L1 13'
                            stroke='black'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </ButtonNext>
                    </div>
                  </CarouselProvider>
                </div>
              </div>
            ) : (
              <ProductImgs image={getImages(p.image_url.images)} />
            )}
          </div>
          <div className='lg:w-3/5 w-full lg:pl-4 md:px-4 sm:px-4 px-4 items-center '>
            <h2 className='font-semibold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 mt-4'>
              {p.SK}
            </h2>
            <p className=' font-semibold lg:text-2xl text-xl mt-8 '>{p.PK}</p>
            <p className='lg:text-2xl text-xl  mt-8 '>{p.description}</p>
            <p className='lg:text-xl text-lg mt-8 '>
              Dimensions : {p.dimensions ? p.dimensions.length : ''}CM X{' '}
              {p.dimensions ? p.dimensions.width : ''}CM X{' '}
              {p.dimensions ? p.dimensions.height : ''}CM
            </p>
            <div className='grid xl:grid-cols-5 lg:grid-cols-2 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-2 mt-8 justify-start'>
              <p className='xl:col-span-2 col-span-1 lg:text-lg text-md text-base leading-4 text-gray-600 my-auto'>
                Color:
              </p>
              <div className='xl:col-span-3 lg:col-span-1 md:col-span-3 sm:col-span-1'>
                <div
                  className='bg-white flex justify-between border rounded lg:w-40 md:w-40 sm:w-36 w-36'
                  onClick={() => setShow(!show)}
                >
                  <p className='px-2 py-2 text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs font-normal'>
                    {filter}
                  </p>
                  <div className='bg-white items-center flex '>
                    <div className='cursor-pointer text-gray-600 mr-3'>
                      {show ? (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke-width='1.5'
                          stroke='currentColor'
                          className='lg:w-6 md:w-6 sm:w-4 w-4 lg:h-6 md:h-6 sm:h-4 h-4'
                        >
                          <path
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            d='M4.5 15.75l7.5-7.5 7.5 7.5'
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke-width='1.5'
                          stroke='currentColor'
                          className='lg:w-6 md:w-6 sm:w-4 w-4 lg:h-6 md:h-6 sm:h-4 h-4'
                        >
                          <path
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
                {show && (
                  <ul className='visible transition duration-300 opacity-100 bg-white shadow rounded mt-2 py-1 lg:w-40 md:w-40 sm:w-36 w-36 absolute cursor-pointer z-10'>
                    {p.color.map((c, index) => (
                      <li
                        className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                        onClick={() => {
                          setFilter(c.item_color + ' ' + c.color_finish);
                          handleColorChange(index);
                          setShow(false);
                          setColour([c.item_color]);
                          setColorNotSelected(false);
                          setCustomization('');
                        }}
                      >
                        {c.item_color + ' ' + c.color_finish}
                        <>
                          <button
                            type='button'
                            className={classNames(
                              c.item_color === 'white'
                                ? 'bg-[#ffffff] hover:bg-[#f0efec]'
                                : c.item_color === 'black'
                                ? 'bg-[#000000] hover:bg-[#353533]'
                                : c.item_color === 'cast'
                                ? 'bg-[#57565b] hover:bg-[#6a6a6d]'
                                : c.item_color === 'blue'
                                ? 'bg-[#425757] hover:bg-[#59646d]'
                                : c.item_color === 'mole'
                                ? 'bg-[#8c866f] hover:bg-[#a39d83]'
                                : c.item_color === 'green'
                                ? 'bg-[#86aaa3] hover:bg-[#9fbebb]'
                                : c.item_color === 'cream'
                                ? 'bg-[#cdc0b7] hover:bg-[#d3c6bd]'
                                : c.item_color === 'sand'
                                ? 'bg-[#e5b39e] hover:bg-[#f0c3b0]'
                                : c.item_color === 'tope'
                                ? 'bg-[#dcd3d2] hover:bg-[#e4dbda]'
                                : c.item_color === 'red'
                                ? 'bg-[#ab1939] hover:bg-[#bd2747]'
                                : c.item_color === 'multi'
                                ? 'bg-[#74361c] hover:bg-[#954522]'
                                : c.item_color === 'silk'
                                ? 'bg-[#c19e8a] hover:bg-[#d6b39f]'
                                : c.item_color === 'dust'
                                ? 'bg-[#674c38] hover:bg-[#685141]'
                                : 'bg-[#7a7484] hover:bg-[#7a7484]',
                              'w-8 h-8 text-white border border-black focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center items-center ml-4'
                            )}
                          ></button>
                        </>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className='grid xl:grid-cols-5 lg:grid-cols-2 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-2 mt-8'>
              <p className='xl:col-span-2 col-span-1 lg:text-lg text-md text-base leading-4 text-gray-600 my-auto'>
                Customization:
              </p>
              <input
                id='customiztion'
                type='text'
                className='block xl:col-span-3 lg:col-span-1 md:col-span-3 sm:col-span-1 px-4 py-2 xl:w-2/3 lg:w-full md:w-1/2 w-full text-black bg-white border rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40'
                value={customization}
                onChange={(e) => {
                  setColour(['customized']);
                  setCustomization(e.target.value);
                  setColorNotSelected(false);
                  setFilter('Select the Color');
                }}
              />
            </div>
            {colorNotSelected && (
              <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs mt-4'>
                Please select a default color or add a customization!
              </p>
            )}
            <div className='mt-8 grid xl:grid-cols-5 lg:grid-cols-2 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-2'>
              <p className='xl:col-span-2 col-span-1 lg:text-lg text-md text-base leading-4 text-gray-600'>
                Select quantity:
              </p>
              <div className='flex xl:col-span-3 lg:col-span-1 md:col-span-3 sm:col-span-1 my-auto'>
                <span
                  onClick={minusCount}
                  className='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-r-0 w-7 h-7 flex items-center justify-center pb-1'
                >
                  -
                </span>
                <input
                  id='counter'
                  aria-label='input'
                  className='border border-gray-300 h-7 text-center w-14 pb-1'
                  type='text'
                  value={count}
                  onChange={(e) => setCount(Number(e.target.value))}
                />
                <span
                  onClick={addCount}
                  className='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-l-0 w-7 h-7 flex items-center justify-center pb-1 '
                >
                  +
                </span>
              </div>
            </div>
            <div className='w-full mt-8'>
              <label className='lg:text-lg text-md text-base leading-4 text-gray-600 mt-8'>
                Notes:
              </label>
              <textarea
                className='block w-full h-32 px-5 py-3 mt-4 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-56 focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40'
                id='notes'
                name='notes'
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              ></textarea>
            </div>

            <div className='grid max-w-2xl sm:grid-cols-2 gap-x-4 gap-y-8 mt-2'>
              <div>
                <button
                  disabled={colorNotSelected}
                  onClick={() => handleAddToCart(p)}
                  className='flex justify-center gap-x-2 rounded-md bg-black px-12 py-4 text-md font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 w-full lg:mt-16 mt-2'
                >
                  <PlusIcon className='h-5 w-5' aria-hidden='true' />
                  Add to Quote
                </button>
              </div>
              <div>
                <button
                  onClick={() => handleAddNewItem()}
                  className='flex justify-center gap-x-2 rounded-md bg-black px-12 py-4 text-md font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 w-full lg:mt-16 mt-2'
                >
                  <DevicePhoneMobileIcon
                    className='h-5 w-5'
                    aria-hidden='true'
                  />
                  Scan New Item
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductDetails;
