import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "font-awesome/css/font-awesome.css";
import { AnalyticsAPI } from "../../apis/analyticsAPI";
import axios from "axios";

function Form() {
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string()
      .required("Contact number is required")
      .matches(
        "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$",
        "Please enter a valid telephone number"
      ),
    email: Yup.string()
      .required("Email address is required")
      .matches(
        "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
        "Please enter a valid email address"
      ),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });
  const validationOpt = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(validationOpt);
  const { errors } = formState;
  async function onFormSubmit(data) {
    try {
      await axios.post(
          `${process.env.REACT_APP_API_URL}/feedback/save`,
          JSON.stringify(data),
          {
            headers: {'x-api-key': process.env.REACT_APP_X_API_KEY},
          },
      )
      callAnalyticAPI("Contact Us Form submitted");
      setSuccessModal(true);
      reset();
      setTimeout(() => {
        setSuccessModal(false)
      }, 3000);
    } catch (error) {
      console.error(error);
      setErrorModal(true);
      setTimeout(() => {
        setErrorModal(false)
      }, 3000);
    }
  }
  function callAnalyticAPI(status) {
    const body = {
      state: status,
      hasPurchasedItems: false,
      hasVisited: window.innerWidth <= 768 ? "exhibition":"website",
      recordedDateTime:  (new Date()).toISOString(),
    }
    AnalyticsAPI.getUserState(
      body
    )
      .then((response) => {})
      .catch((error) => {});
  }

  useEffect(() => {
    callAnalyticAPI("On Contact Us page");
  }, []);

  return (
    <div>
      {successModal ? (
        <div
          style={{
            position: 'fixed',
            top: '30px',
            zIndex: 100
          }}
          className="flex lg:w-1/3 md:w-2/3 w-full lg:absolute md:absolute lg:right-0 md:right-0 p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200"
          role="alert"
        >
          <svg
            aria-hidden="true"
            className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
            Your message sent successfully!!
          </div>
          <button
            type="button"
            onClick={() => {
              setSuccessModal(false);
            }}
            className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300"
            data-dismiss-target="#alert-3"
            aria-label="Close"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      ) : null}
      {errorModal ? (
        <div
          style={{
            position: 'fixed',
            top: '30px',
            zIndex: 100
          }}
          id="alert-2"
          className="flex lg:w-1/3 md:w-2/3 w-full lg:absolute md:absolute lg:right-0 md:right-0 p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200"
          role="alert"
        >
          <svg
            aria-hidden="true"
            className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>

          <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
            Sorry! Please Try again
          </div>
          <button
            type="button"
            onClick={() => {
              setErrorModal(false);
            }}
            className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300"
            data-dismiss-target="#alert-2"
            aria-label="Close"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      ) : null}
      <section className="min-h-screen bg-white ">
        <div className="container px-6 py-10 mx-auto">
          <div className="lg:flex lg:items-center lg:-mx-10">
            <div className="mt-12 lg:flex lg:mt-0 lg:flex-col lg:items-center lg:w-1/2 lg:mx-10">
              <div className="mt-6 space-y-8 md:mt-8">
                <h3 className="lg:text-2xl md:text-xl sm:text-lg text-lg">
                  Find Us Here,
                </h3>
                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://wNameww.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-12 h-12 mx-2 text-gray-500 bg-gray-200 p-3 rounded-full "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>

                  <span className="mx-4">
                    <p className="font-bold text-gray-600">Address</p>
                    <p className="text-gray-500">
                      Yatiyana Road, Galtude, Bandaragama, Sri Lanka
                    </p>
                  </span>
                </p>

                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-12 h-12 mx-2 text-gray-500 bg-gray-200 p-3 rounded-full "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>

                  <span className="mx-4">
                    <p className="font-bold text-gray-600">Email</p>
                    <p className="text-gray-500">info@artdecoint.com</p>
                  </span>
                </p>

                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-12 h-12 mx-2 text-gray-500 bg-gray-200 p-3 rounded-full"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                    />
                  </svg>

                  <span className="mx-4">
                    <p className="font-bold text-gray-600">Phone</p>
                    <p className="text-gray-500">
                      +94 38 22 90093, +94 38 56 78578
                    </p>
                  </span>
                </p>

                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-12 h-12 mx-2 text-gray-500 bg-gray-200 p-3 rounded-full"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                    />
                  </svg>

                  <span className="mx-4">
                    <p className="font-bold text-gray-600">Fax</p>
                    <p className="text-gray-500">+94 38 22 90092</p>
                  </span>
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 lg:mx-10 md:my-16 sm:my-12 my-12">
              <h3 className="lg:text-2xl md:text-xl sm:text-lg text-lg">
                Contact Us
              </h3>

              <form onSubmit={handleSubmit(onFormSubmit)} className="mt-12">
                <div className="-mx-2 my-4 md:items-center md:flex">
                  <div className="flex-1 px-2">
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Name*
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      {...register("name")}
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <div className="text-red-700 italic">
                      {errors.name?.message}
                    </div>
                  </div>

                  <div className="flex-1 px-2 mt-4 md:mt-0">
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Phone Number*
                    </label>
                    <input
                      type="phone"
                      id="phone"
                      name="phone"
                      {...register("phone")}
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <div className="text-red-700 italic">
                      {errors.phone?.message}
                    </div>
                  </div>
                </div>
                <div className="-mx-2 my-4 md:items-center md:flex">
                  <div className="flex-1 px-2">
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Email*
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      {...register("email")}
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <div className="text-red-700 italic">
                      {errors.email?.message}
                    </div>
                  </div>

                  <div className="flex-1 px-2 mt-4 md:mt-0">
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Subject*
                    </label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      {...register("subject")}
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <div className="text-red-700 italic">
                      {errors.subject?.message}
                    </div>
                  </div>
                </div>

                <div className="w-full mt-4">
                  <label className="block mb-2 text-sm text-gray-600 ">
                    Message*
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    {...register("message")}
                    className="block w-full h-16 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-56   focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  ></textarea>
                  <div className="text-red-700 italic">
                    {errors.message?.message}
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    className="w-1/4 px-auto py-3 mt-4  text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-800 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.6040361452906!2d79.98023441538295!3d6.695865095158958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2490c135bf91b%3A0x44e873b36f0788e8!2sArt%20Decoration%20International!5e0!3m2!1sen!2slk!4v1671263717816!5m2!1sen!2slk"
          width="100%"
          title="location"
          height="500"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </div>
  );
}

export default Form;
