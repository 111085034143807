import React from 'react'
import Dashboard from '../../components/Admin/Dashboard/Dashboard'

function ViewAdminStatistics() {
  return (
    <div>
      <Dashboard tab={7} title="Statistics" />
    </div>
  )
}

export default ViewAdminStatistics
