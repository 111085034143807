import React, { useEffect, useState } from 'react'
import { DocumentArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline'

import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useClientsContext } from '../../../../context/ClientsContext'
import Loading from '../../Auth/Loading'

function ViewEditClient({ clientId, onClose, onUpdate }) {
  const { getClientByID, updateClient } = useClientsContext()
  const [clientData, setClientData] = useState(null)
  const [selectedFile, setSelectedFile] = useState('')
  const onSubmit = async (data) => {
    try {
      await updateClient(clientId, data)
      setTimeout(() => {
        onClose()
      }, 1000)
    } catch (error) {}
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const base64String = e.target.result
      setSelectedFile(base64String)
      setValue('updatedBusinessCard', base64String)
    }
    reader.readAsDataURL(file)
    setSelectedFile(file ? file.name : '')
  }
  const clientDetailsSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .min(4, 'Atleast 3 characters required')
      .max(500, "Can't exceed 500 characters"),
    lastName: Yup.string()
      .required('Last Name is required')
      .min(4, 'Atleast 3 characters required')
      .max(500, "Can't exceed 500 characters"),
    contactNumber1: Yup.string()
      .required('Contact Number 1 is required')
      .matches(
        '^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$',
        'Please enter a valid telephone number',
      ),
    businessCardUrl: Yup.string().required('Choose Image'),
    updatedBusinessCard: Yup.mixed()
      .required('Business card is required')

      .test('fileType', 'Unsupported file type', (value) => {
        if (value && value.length > 0 && value[0].type) {
          return value[0].type.startsWith('image/')
        }
        return true // Skip the test if value is undefined, empty, or type is undefined
      }),

    email: Yup.string()
      .required('Email is required')
      .email('Valid email address is required'),
    companyName: Yup.string().required('Company Name is required'),
    title: Yup.string().required('Title is required'),
    website: Yup.string().required('Website is required'),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(clientDetailsSchema),
    defaultValues: async () => {
      const client = await getClientByID(clientId)
      return {
        PK: client[0].PK,
        SK: client[0].SK,
        businessCardUrl: client[0].businessCardUrl,
        buyerCategory: client[0].buyerCategory,
        updatedBusinessCard: client[0].updatedBusinessCard || '',
        companyName: client[0].companyName,
        contactNumber1: client[0].contactNumber1,
        contactNumber2: client[0].contactNumber2,
        email: client[0].email,
        firstName: client[0].firstName,
        lastName: client[0].lastName,
        title: client[0].title,
        website: client[0].website,
      }
    },
    mode: 'all',
  })

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const client = await getClientByID(clientId)
        setClientData(client[0])
      } catch (error) {}
    }
    fetchClientData()
  }, [clientId, getClientByID])
  if (!clientData) {
    return <Loading />
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-4xl overflow-y-auto"
    >
      <div className="h-0 flex-1">
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5">
              <div className="flex justify-between border-b text-gray-900 text-3xl">
                <b>Edit Client Details</b>
                <XMarkIcon
                  onClick={() => onClose()}
                  className="h-10 w-10 text-gray-500 cursor-pointer"
                />
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-2 ">
                <div className="sm:col-span-full">
                  <label
                    htmlFor="buyerCategory"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Buyer Type
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="buyerCategory"
                      disabled
                      {...register('buyerCategory')}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="firstName"
                      {...register('firstName')}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div>
                      <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                        {errors.firstName?.message}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="lastName"
                      {...register('lastName')}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div>
                      <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                        {errors.lastname?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Company Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="companyName"
                      {...register('companyName')}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div>
                      <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                        {errors.companyName?.message}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Title
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="title"
                      {...register('title')}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div>
                      <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                        {errors.title?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="contactNumber1"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Contact Number #1
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="contactNumber1"
                      {...register('contactNumber1')}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div>
                      <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                        {errors.contactNumber1?.message}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="contactNumber2"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Contact Number #2
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="contactNumber2"
                      {...register('contactNumber2')}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email Address
                  </label>
                  <div className="mt-2">
                    <input
                      type="email"
                      id="email"
                      {...register('email')}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div>
                      <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                        {errors.email?.message}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Website
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="website"
                      {...register('website')}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div>
                      <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                        {errors.website?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="updatedBusinessCard"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Business Card
                    <label className="text-xs text-gray-600">
                      (Upload Image: Jpg,Png)
                    </label>
                  </label>
                  <div className="mt-2 flex rounded-md shadow-sm">
                    <div className="relative flex flex-grow items-stretch focus-within:z-10">
                      <input
                        type="text"
                        id="updatedBusinessCard"
                        value={selectedFile}
                        readOnly
                        className="block w-full rounded-none rounded-l-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <label className="relative cursor-pointer">
                      <input
                        id="updatedBusinessCard"
                        type="file"
                        accept="image/*"
                        className="sr-only"
                        onChange={handleFileChange}
                      />
                      <div className="flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <DocumentArrowUpIcon
                          className="-ml-0.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span>Choose File</span>
                      </div>
                    </label>
                    <div>
                      <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                        {errors.updatedBusinessCard?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-shrink-0 justify-end mt-10 border-t border-gray-900/10  px-4 py-4">
                <button
                  type="button"
                  className="ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-gray-800 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button
                  disabled={!isDirty || !isValid || isSubmitting}
                  type="submit"
                  className={`ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-blue-800 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ${
                    !isDirty || !isValid || isSubmitting
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ViewEditClient
