import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import ButtonLoader from "../Shared/ButtonLoader";

window.Buffer = window.Buffer || require("buffer").Buffer;

const schema = yup.object().shape({
  firstName: Yup.string()
      .required('First Name is required')
      .min(4, 'At least 3 characters required')
      .max(500, "Can't exceed 500 characters"),
  lastName: Yup.string()
      .required('Last Name is required')
      .min(4, 'At least 3 characters required')
      .max(500, "Can't exceed 500 characters"),
  companyName: Yup.string().required('Company Name is required'),
  title: Yup.string().required('Title is required'),
  contactNumber1: Yup.string()
      .required('Contact Number 1 is required')
      .matches(
          '^(?:(?:\\+|00)\\d{1,3}\\s?)?(?:\\(?\\d{2,3}\\)?[-.\\s]?)?\\d{3}[-.\\s]?\\d{4}$',
          'Please enter a valid telephone number',
      ),
  email: Yup.string()
      .required('Email is required')
      .email('Valid email address is required'),

  website: Yup.string().required('Website is required'),
  businessCardData: Yup.mixed()
      .required('Business Card is required')

      .test('fileType', 'Unsupported file type', (value) => {
        if (value && value.length > 0 && value[0].type) {
          return value[0].type.startsWith('image/')
        }
        return true // Skip the test if value is undefined, empty, or type is undefined
      }),
});

const RegistrationForm = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState("Whole seller");
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      buyerCategory: '',
      firstName: '',
      lastName: '',
      companyName: '',
      title: '',
      contactNumber1: '',
      contactNumber2: '',
      email: '',
      website: '',
      businessCardData: '',
    },
    mode: 'all',
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const base64String = e.target.result
      setSelectedFile(base64String)
      setValue('businessCardData', base64String)
    }
    reader.readAsDataURL(file)
    setSelectedFile(file ? file.name : '')
  }

  async function onSubmitHandler(data) {
    data.buyerCategory = filter;
    data = { ...data, businessCardData: selectedFile };
    setIsLoading(true);
    try {
      await axios.post(
          `${process.env.REACT_APP_API_URL}/customer/save`,
          JSON.stringify(data),
          {
            headers: {'x-api-key': process.env.REACT_APP_X_API_KEY},
          },
      )
      setSuccessModal(true);
      reset();
      setIsLoading(false);
      setTimeout(() => {
        setSuccessModal(false)
      }, 3000);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setErrorModal(true);
      setTimeout(() => {
        setErrorModal(false)
      }, 3000);
    }

  }

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50 overflow-y-auto">
      {successModal ? (
          <div
              style={{
                position: 'fixed',
                top: '30px',
                zIndex: 100
              }}
              className="flex lg:w-1/3 md:w-2/3 w-full lg:absolute md:absolute lg:right-0 md:right-0 p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200"
              role="alert"
          >
            <svg
                aria-hidden="true"
                className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
              ></path>
            </svg>
            <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
              Your details submitted successfully
            </div>
            <button
                type="button"
                onClick={() => {
                  setSuccessModal(false);
                }}
                className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300"
                data-dismiss-target="#alert-3"
                aria-label="Close"
            >
              <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
      ) : null}
      {errorModal ? (
          <div
              style={{
                position: 'fixed',
                top: '30px',
                zIndex: 100
              }}
              id="alert-2"
              className="flex lg:w-1/3 md:w-2/3 w-full lg:absolute md:absolute lg:right-0 md:right-0 p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200"
              role="alert"
          >
            <svg
                aria-hidden="true"
                className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
              ></path>
            </svg>

            <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
              Sorry! Please Try again
            </div>
            <button
                type="button"
                onClick={() => {
                  setErrorModal(false);
                }}
                className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300"
                data-dismiss-target="#alert-2"
                aria-label="Close"
            >
              <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
      ) : null}
      <section className="max-w-4xl lg:p-12 md:p-8 sm:p-6 p-6 mx-auto bg-white rounded-md shadow-md ">
        <h1 className="font-bold lg:text-2xl md:text-lg sm:text-md text-md text-center text-black   uppercase">
          Enter Your Contact Information
        </h1>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="flex mt-6">
            <label
              className="text-black lg:text-lg md:text-md sm:text-sm text-xs "
              for="buyerCategory"
            >
              Select the Buyer Type* :
            </label>
            <div className="relative ml-6">
              <div
                className="bg-white flex justify-between border rounded w-auto px-2"
                onClick={() => setShow(!show)}
              >
                {" "}
                <p className="px-2 py-2 text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs font-normal">
                  {filter}
                </p>
                <div className="bg-white items-center flex ">
                  <div className="cursor-pointer text-gray-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 my-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {show && (
                <ul className="visible transition duration-300 opacity-100 bg-white shadow rounded mt-2 py-1 w-auto absolute cursor-pointer z-10">
                  <li
                    className="cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center"
                    onClick={() => {
                      setFilter("Whole seller");
                      setShow(false);
                    }}
                  >
                    Whole seller
                  </li>
                  <li
                    className="cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center"
                    onClick={() => {
                      setFilter("Retailer");
                      setShow(false);
                    }}
                  >
                    Retailer
                  </li>
                  <li
                    className="cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center"
                    onClick={() => {
                      setFilter("Trader");
                      setShow(false);
                    }}
                  >
                    Trader
                  </li>
                  <li
                    className="cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center"
                    onClick={() => {
                      setFilter("Buying office");
                      setShow(false);
                    }}
                  >
                    Buying office
                  </li>
                  <li
                    className="cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center"
                    onClick={() => {
                      setFilter("Hypermarket chain");
                      setShow(false);
                    }}
                  >
                    Hypermarket chain
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 lg:gap-6 md:gap-6 gap-4 mt-4 sm:grid-cols-2">
            <div>
              <div>
                <label
                  className="text-black  lg:text-lg md:text-md sm:text-sm text-xs "
                  for="firstName"
                >
                  First Name*
                </label>
                <input
                  id="firstName"
                  type="text"
                  className="block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black focus:outline-none focus:ring"
                  {...register("firstName")}
                />
              </div>
              <div>
                <div>
                  <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                    {errors.firstName?.message}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <label
                  className="text-black lg:text-lg md:text-md sm:text-sm text-xs "
                  for="lastName"
                >
                  Last Name*
                </label>
                <input
                  id="lastName"
                  type="text"
                  className="block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black  focus:outline-none focus:ring"
                  {...register("lastName")}
                />
              </div>
              <div>
                <div>
                  <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                    {errors.lastName?.message}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <label
                  className="text-black lg:text-lg md:text-md sm:text-sm text-xs "
                  for="companyName"
                >
                  Company Name*
                </label>
                <input
                  id="companyName"
                  type="text"
                  className="block w-full px-4 py-2   mt-2
           text-black bg-white border rounded-md
            focus:border-black focus:outline-none focus:ring"
                  {...register("companyName")}
                />
              </div>
              <div>
                <div>
                  <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                    {errors.companyName?.message}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <label
                  className="text-black lg:text-lg md:text-md sm:text-sm text-xs"
                  for="title"
                >
                  Title*
                </label>
                <input
                  id="title"
                  type="text"
                  className="block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black focus:outline-none focus:ring"
                  {...register("title")}
                />
              </div>
              <div>
                <div>
                  <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                    {errors.title?.message}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <label
                  className="text-black lg:text-lg md:text-md sm:text-sm text-xs"
                  for="contactNumber1"
                >
                  Contact Number 1*
                </label>
                <input
                  id="contactNumber1"
                  type="text"
                  className="block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
             focus:border-black  focus:outline-none focus:ring"
                  {...register("contactNumber1")}
                />
              </div>
              <div>
                <div>
                  <p className="text-red-700  italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                    {errors.contactNumber1?.message}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <label
                  className="text-black  lg:text-lg md:text-md sm:text-sm text-xs "
                  for="contactNumber2"
                >
                  Contact Number 2
                </label>
                <input
                  id="contactNumber2"
                  type="text"
                  className="block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md 
            focus:border-black focus:outline-none focus:ring"
                  {...register("contactNumber2")}
                />
              </div>
              <div>
                <div>
                  <p className="text-red-700  italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                    {errors.contactNumber2?.message}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <label
                  className="text-black lg:text-lg md:text-md sm:text-sm text-xs "
                  for="emailAddress"
                >
                  Email Address*
                </label>
                <input
                  id="emailAddress"
                  type="email"
                  className="block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black  focus:outline-none focus:ring"
                  {...register("email")}
                />
              </div>
              <div>
                <div>
                  <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                    {errors.email?.message}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <label
                  className="text-black lg:text-lg md:text-md sm:text-sm text-xs"
                  for="website"
                >
                  Web Site*
                </label>
                <input
                  id="website"
                  type="website"
                  className="block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black  focus:outline-none focus:ring"
                  {...register("website")}
                />
              </div>
              <div>
                <div>
                  <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                    {errors.website?.message}
                  </p>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="flex my-4">
            <label
              className="text-black lg:text-lg md:text-md sm:text-sm text-xs "
              for="businessCardData"
            >
              Attach your Business Card :
            </label>
            <input
                id="businessCardData"
              type="file"
              onChange={handleFileChange}
              className="block w-1/2 ml-8 text-sm text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100"
            />
          </div>
          <div className="my-8">
            <label
              className="text-black lg:text-lg md:text-md sm:text-sm text-xs"
              for="emailAddress"
            >
              By submitting this form, you consent to receive transactional
              emails from artdecorint.com
            </label>
          </div>

          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="px-7 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md
       hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            >
              <ButtonLoader isLoading={isLoading}></ButtonLoader>
              Save
            </button>
            <Link to="/">
              <button
                className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md
       hover:bg-gray-600 focus:outline-none focus:bg-gray-600 ml-2"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </section>
    </div>
  );
};

export default RegistrationForm;
